import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { UpdateUserUsecase } from "@/application/user/update-user/usecase/UpdateUserUseCase";
import { UpdateUserModel } from "@/domain/user/update-user/model/UpdateUserModel";
import { AboutUserUseCase } from "@/application/user/about-user/usecase/AboutUserUseCase";
import { ChangeTenantEstateUseCase } from "@/application/auth/change-tenant-estate/usecase/ChangeTenantEstateUseCase";
import { ChangeTenantEstateModel } from "@/domain/auth/change-tenant-estate/model/ChangeTenantEstateModel";

export function createUserController() {
  const updateUserUseCase = new UpdateUserUsecase();
  const aboutUserUseCase = new AboutUserUseCase();
  const changeTenantEstateUseCase = new ChangeTenantEstateUseCase();

  return new UserController(
    updateUserUseCase,
    aboutUserUseCase,
    changeTenantEstateUseCase
  );
}

export class UserController implements IController {
  constructor(
    private updateUserUseCase: UpdateUserUsecase,
    private aboutUserUseCase: AboutUserUseCase,
    private changeTenantEstateUseCase: ChangeTenantEstateUseCase
  ) {}

  async aboutUser(): Promise<Result<UpdateUserModel>> {
    const response = await this.aboutUserUseCase.execute();

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<UpdateUserModel>(response.getError());
    }
  }

  async updateUser(
    updateUserModel: UpdateUserModel
  ): Promise<Result<UpdateUserModel>> {
    const response = await this.updateUserUseCase.execute(updateUserModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<UpdateUserModel>(response.getError());
    }
  }

  async changeTenantEstate(
    model: ChangeTenantEstateModel
  ): Promise<Result<ChangeTenantEstateModel>> {
    const response = await this.changeTenantEstateUseCase.execute(model);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<ChangeTenantEstateModel>(response.getError());
    }
  }
}
