import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { CountryListUseCase } from "@/application/country/country-list/usecase/CountryListUseCase";
import { CountryListModel } from "@/domain/country/country-list/model/CountryListModel";

export function createCountryController() {
  const countryListUseCase = new CountryListUseCase();
  return new CountryController(countryListUseCase);
}

export class CountryController implements IController {
  constructor(private countryListUseCase: CountryListUseCase) {}

  async countryList(
    countryListModel: CountryListModel
  ): Promise<Result<CountryListModel>> {
    const response = await this.countryListUseCase.execute(countryListModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<CountryListModel>(response.getError());
    }
  }
}
