import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { FileUploadUsecase } from "@/application/file-upload/usecase/FileUploadUseCase";
import { FileUploadModel } from "@/domain/file-upload/model/FileUploadModel";

export function createFileController() {
  const fileUploadUseCase = new FileUploadUsecase();
  return new FileController(fileUploadUseCase);
}

export class FileController implements IController {
  constructor(private fileUploadUseCase: FileUploadUsecase) {}

  async fileUpload(
    fileUploadModel: FileUploadModel
  ): Promise<Result<FileUploadModel>> {
    const response = await this.fileUploadUseCase.execute(fileUploadModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<FileUploadModel>(response.getError());
    }
  }
}
