import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { JobPostingListUseCase } from "@/application/interview/job-posting-list/usecase/JobPostingListUseCase";
import { JobPostingListModel } from "@/domain/interview/job-posting-list/model/JobPostingListModel";
import { DeleteJobPostingModel } from "@/domain/interview/delete-job-posting/model/DeleteJobPostingModel";
import { DeleteJobPostingUseCase } from "@/application/interview/delete-job-posting/usecase/DeleteJobPostingUseCase";
import { CreateJobPostingModel } from "@/domain/interview/create-job-posting/model/CreateJobPostingModel";
import { CreateJobPostingUsecase } from "@/application/interview/create-job-posting/usecase/CreateJobPostingUseCase";
import { JobPostingDetailModel } from "@/domain/interview/job-posting-detail/model/JobPostingDetailModel";
import { JobPostingDetailUseCase } from "@/application/interview/job-posting-detail/usecase/JobPostingDetailUseCase";
import { AddCandidateModel } from "@/domain/interview/add-candidate/model/AddCandidateModel";
import { AddCandidateUsecase } from "@/application/interview/add-candidate/usecase/AddCandidateUseCase";
import { UpdateJobPostingModel } from "@/domain/interview/update-job-posting/model/UpdateJobPostingModel";
import { UpdateJobPostingUseCase } from "@/application/interview/update-job-posting/usecase/UpdateJobPostingUseCase";

export function createInterviewController() {
  const jobPostingListUseCase = new JobPostingListUseCase();
  const deleteJobPostingUseCase = new DeleteJobPostingUseCase();
  const createJobPostingUseCase = new CreateJobPostingUsecase();
  const jobPostingDetailUseCase = new JobPostingDetailUseCase();
  const addCandidateUseCase = new AddCandidateUsecase();
  const updateJobPostingUseCase = new UpdateJobPostingUseCase();

  return new InterviewController(
    jobPostingListUseCase,
    deleteJobPostingUseCase,
    createJobPostingUseCase,
    jobPostingDetailUseCase,
    addCandidateUseCase,
    updateJobPostingUseCase
  );
}

export class InterviewController implements IController {
  constructor(
    private jobPostingListUseCase: JobPostingListUseCase,
    private deleteJobPostingUseCase: DeleteJobPostingUseCase,
    private createJobPostingUseCase: CreateJobPostingUsecase,
    private jobPostingDetailUseCase: JobPostingDetailUseCase,
    private addCandidateUseCase: AddCandidateUsecase,
    private updateJobPostingUseCase: UpdateJobPostingUseCase,
  ) {}

  async createJobPosting(
    createJobPostingModel: CreateJobPostingModel
  ): Promise<Result<CreateJobPostingModel>> {
    const response = await this.createJobPostingUseCase.execute(
      createJobPostingModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<CreateJobPostingModel>(response.getError());
    }
  }

  async updateJobPosting(
    updateJobPostingModel: UpdateJobPostingModel
  ): Promise<Result<CreateJobPostingModel>> {
    const response = await this.updateJobPostingUseCase.execute(
      updateJobPostingModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<CreateJobPostingModel>(response.getError());
    }
  }

  async jobPostingList(
    jobPostinglistModel: JobPostingListModel
  ): Promise<Result<JobPostingListModel>> {
    const response = await this.jobPostingListUseCase.execute(
      jobPostinglistModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<JobPostingListModel>(response.getError());
    }
  }

  async deleteJobPosting(
    deleteJobPostingModel: DeleteJobPostingModel
  ): Promise<Result<DeleteJobPostingModel>> {
    const response = await this.deleteJobPostingUseCase.execute(
      deleteJobPostingModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<DeleteJobPostingModel>(response.getError());
    }
  }

  async getJobPostingDetail(
    jobPostingDetailModel: JobPostingDetailModel
  ): Promise<Result<JobPostingDetailModel>> {
    const response = await this.jobPostingDetailUseCase.execute(
      jobPostingDetailModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<JobPostingDetailModel>(response.getError());
    }
  }

  async addCandidate(
    addCandidateModel: AddCandidateModel
  ): Promise<Result<AddCandidateModel>> {
    const response = await this.addCandidateUseCase.execute(addCandidateModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AddCandidateModel>(response.getError());
    }
  }
}
