
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { PollSessionDetailRepository } from "../repository/PollSessionDetailRepository";
import { PollSessionDetailMapper } from "../mapper/PollSessionDetailMapper";
import { IPollSessionDetailUseCase } from "@/domain/pollster/poll-statistic/poll-session-detail/usecase/IPollSessionDetailUseCase";
import { IPollSessionDetailRepository } from "@/domain/pollster/poll-statistic/poll-session-detail/repository/IPollSessionDetailRepository";
import { PollSessionDetailModel } from "@/domain/pollster/poll-statistic/poll-session-detail/model/PollSessionDetailModel";

export class PollSessionDetailUseCase implements IPollSessionDetailUseCase {
  getPollSessionDetailRepository : IPollSessionDetailRepository = new PollSessionDetailRepository();
  getPollSessionDetailMapper = new PollSessionDetailMapper ();

  constructor() {
  }

  async execute(model: PollSessionDetailModel): Promise<Result<PollSessionDetailModel>> {
    try {
      const dto = this.getPollSessionDetailMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<PollSessionDetailModel>(dto.getError());
      } else {
        const response = await this.getPollSessionDetailRepository.getPollSessionDetail(dto);

        if (response.isSuccess) {
          const domain = this.getPollSessionDetailMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<PollSessionDetailModel>(domain.getValue());
        } else {
          return Result.fail<PollSessionDetailModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<PollSessionDetailModel>(
        new UseCaseException(new UnknownCause(USECASE.POLLSTER.STATISTIC.POLL_SESSION_DETAIL))
      );
    }
  }
}
