import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { JobPostingDetailResponseDto } from "@/domain/interview/job-posting-detail/dto/JobPostingDetailResponseDto";
import { JobPostingDetailModel } from "@/domain/interview/job-posting-detail/model/JobPostingDetailModel";
import { JobPostingDetailRequestDto } from "@/domain/interview/job-posting-detail/dto/JobPostingDetailRequestDto";

export class JobPostingDetailMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: JobPostingDetailResponseDto): Result<JobPostingDetailModel> {
    try {
      if (dto.data) {
        const interviewModel: JobPostingDetailModel = {
          id: dto.data.id,
          title: dto.data.attributes.title,
          position: dto.data.attributes.position,
          description: dto.data.attributes.description,
          status: dto.data.attributes.interview.data.attributes.status,
          startDate: dto.data.attributes.interview.data.attributes.startDate,
          endDate: dto.data.attributes.interview.data.attributes.endDate,
          interviewId: dto.data.attributes.interview.data.id,
          interviewTitle: dto.data.attributes.interview.data.attributes.title,
          interviewDesc:
            dto.data.attributes.interview.data.attributes.description,
          interviewQuestions:
            dto.data.attributes.interview.data.attributes.interviewQuestions.data.map(
              (item) => ({
                interviewQuestionOptions:
                  item.attributes.interviewQuestionOptions.data.map((item) => ({
                    optionText: item.attributes.optionText,
                  })),
                questionText: item.attributes.questionText,
                questionType: item.attributes.questionType,
              })
            ),
        };

        return Result.ok(interviewModel);
      } else {
        const interviewModel: JobPostingDetailModel = { interviewQuestions: [] };
        return Result.ok(interviewModel);
      }
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.INTERVIEW_DETAIL))
      );
    }
  }

  mapToDTO(domain: JobPostingDetailModel): Result<JobPostingDetailRequestDto> {
    try {
      const interviewRequest: JobPostingDetailRequestDto = {
        id: domain.id,
      };

      return Result.ok(interviewRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.INTERVIEW_DETAIL))
      );
    }
  }
}
