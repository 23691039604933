import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { PollMessageListModel } from "@/domain/pollster/poll-statistic/poll-message-list/model/PollMessageListModel";
import { PollMessageListResponseDto } from "@/domain/pollster/poll-statistic/poll-message-list/dto/PollMessageListResponseDto";
import { PollMessageListRequestDto } from "@/domain/pollster/poll-statistic/poll-message-list/dto/PollMessageListRequestDto";

export class PollMessageListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: PollMessageListResponseDto): Result<PollMessageListModel[]> {
    try {
      const pollMessageList: PollMessageListModel[] = [];

      if (dto.data.length > 0) {
        for (const item of dto.data) {
          const pollMessageListModel: PollMessageListModel = {
            poll: {
              id: Number(item.id),
              owner: item.attributes.owner,
              text: item.attributes.text,
              isLiked: item.attributes.isLiked,
              createdAt: item.attributes.createdAt,
              video: item.attributes.video,
              voice: item.attributes.voice,
            },
          };

          pollMessageList.push(pollMessageListModel);
        }
        return Result.ok(pollMessageList);
      }
      return Result.ok(pollMessageList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.STATISTIC.POLL_MESSAGE_LIST))
      );
    }
  }

  mapToDTO(domain: PollMessageListModel): Result<PollMessageListRequestDto> {
    try {
      const pollMessageListRequest: PollMessageListRequestDto = {
        id: domain.poll.id,
      };

      return Result.ok(pollMessageListRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.STATISTIC.POLL_MESSAGE_LIST))
      );
    }
  }
}
