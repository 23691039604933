import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { SessionListUseCase } from "@/application/hotel-management/session-list/usecase/SessionListUseCase";
import { SessionListModel } from "@/domain/hotel-management/session-list/model/SessionListModel";
import { SendMessageUsecase } from "@/application/hotel-management/send-message/usecase/SendMessageUseCase";
import { SendMessageModel } from "@/domain/hotel-management/send-message/model/SendMessageModel";
import { RetryAnswerModel } from "@/domain/hotel-management/retry-answer/model/RetryAnswerModel";
import { RetryAnswerUseCase } from "@/application/hotel-management/retry-answer/usecase/RetryAnswerUseCase";
import { TenantUsersSessionListModel } from "@/domain/hotel-management/tenant-users-session-list/model/TenantUsersSessionListModel";
import { TenantUsersSessionListUseCase } from "@/application/hotel-management/tenant-users-session-list/usecase/TenantUsersSessionListUseCase";

export function createSessionController() {
  const sessionListUseCase = new SessionListUseCase();
  const tenantUsersSessionListUseCase = new TenantUsersSessionListUseCase();
  const sendMessageUseCase = new SendMessageUsecase();
  const retryAnswerUseCase = new RetryAnswerUseCase();

  return new SessionController(
    sessionListUseCase,
    tenantUsersSessionListUseCase,
    sendMessageUseCase,
    retryAnswerUseCase
  );
}

export class SessionController implements IController {
  constructor(
    private sessionListUseCase: SessionListUseCase,
    private tenantUsersSessionListUseCase: TenantUsersSessionListUseCase,
    private sendMessageUseCase: SendMessageUsecase,
    private retryAnswerUseCase: RetryAnswerUseCase
  ) {}

  async sessionList(
    sessionListModel: SessionListModel
  ): Promise<Result<SessionListModel>> {
    const response = await this.sessionListUseCase.execute(sessionListModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<SessionListModel>(response.getError());
    }
  }

  async tenantUsersSessionList(
    tenantUsersSessionListModel: TenantUsersSessionListModel
  ): Promise<Result<TenantUsersSessionListModel>> {
    const response = await this.tenantUsersSessionListUseCase.execute(
      tenantUsersSessionListModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<TenantUsersSessionListModel>(response.getError());
    }
  }

  async sendMessage(
    sendMessageModel: SendMessageModel
  ): Promise<Result<SendMessageModel>> {
    const response = await this.sendMessageUseCase.execute(sendMessageModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<SendMessageModel>(response.getError());
    }
  }

  async retryAnswer(
    retryAnswerModel: RetryAnswerModel
  ): Promise<Result<RetryAnswerModel>> {
    const response = await this.retryAnswerUseCase.execute(retryAnswerModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<RetryAnswerModel>(response.getError());
    }
  }
}
