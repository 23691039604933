import { BaseDTO } from "@/core/base/BaseDTO";

export class AcceptInviteRequestDto extends BaseDTO {
  data: {
    token?: string;
  };

  constructor(token?: string) {
    super();
    this.data = {
      token,
    };
  }
}
