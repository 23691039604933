import { BaseDTO } from "@/core/base/BaseDTO";

export class InviteUserRequestDto extends BaseDTO {
  data: {
    survey?: number;
    emails?: string[] | string;
  }
  constructor(
    survey?: number,
    emails?: string[] | string,
  ) {
    super();
    this.data = {
      survey,
      emails
    }
  }
}
