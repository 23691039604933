import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { IInviteStaffRepository } from "@/domain/staff/invite-staff/repository/IInviteStaffRepository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import InviteStaffService from "../service/InviteStaffService";
import { InviteStaffRequestDto } from "@/domain/staff/invite-staff/dto/InviteStaffRequestDto";

const inviteUserService = new InviteStaffService();

export class InviteStaffRepository implements IInviteStaffRepository {
  async inviteUser(request: InviteStaffRequestDto): Promise<Result<any>> {
    try {
      return await inviteUserService
        .invite(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>(new BadRequestException(error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.STAFF.INVITE_STAFF)));
    }
  }
}
