import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";
import { StaffListRequestDto } from "@/domain/staff/staff-list/dto/StaffListRequestDto";
import { StaffListResponseDto } from "@/domain/staff/staff-list/dto/StaffListResponseDto";
import { StaffListModel } from "@/domain/staff/staff-list/model/StaffListModel";

const paginationMapper = new PaginationMapper();

export class StaffListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: StaffListResponseDto): Result<StaffListModel[]> {
    try {
      const staffList: StaffListModel[] = [];

      if (dto.data.length > 0) {
        for (const item of dto.data) {
          const staffListModel: StaffListModel = {
            staff: {
              id: Number(item.id),
              name: item.attributes.name,
              surname: item.attributes.surname,
              email: item.attributes.email,
              department: item.attributes.department,
              position: item.attributes.position,
              countryCodeNumber: item.attributes.countryCode?.data.attributes.code,
              phoneNumber: item.attributes.phoneNumber,
              isActive: item.attributes.isActive,
              role: item.attributes.activeTenantEstate.data.attributes.tenantRole.data.attributes.shortCode
            },
            page: 0,
            pageSize: 0,
            pagination: dto.meta.pagination,
          };

          staffList.push(staffListModel);
        }
        return Result.ok(staffList);
      }
      return Result.ok(staffList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.STAFF.STAFF_LIST))
      );
    }
  }

  mapToDTO(domain: StaffListModel): Result<StaffListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const StaffListRequest = new StaffListRequestDto(pagiRequest);

      return Result.ok(StaffListRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.STAFF.STAFF_LIST))
      );
    }
  }
}
