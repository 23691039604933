import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IStatisticPollListService } from "@/domain/pollster/poll-statistic/poll-list/service/IPollListService";
import { StatisticPollListRequestDto } from "@/domain/pollster/poll-statistic/poll-list/dto/PollListRequestDto";
import { StatisticPollListResponseDto } from "@/domain/pollster/poll-statistic/poll-list/dto/PollListResponseDto";

export default class StatisticPollListService
  implements IStatisticPollListService
{
  async getList(
    request: StatisticPollListRequestDto
  ): Promise<Result<StatisticPollListResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.SURVEYS_SESSIONS + request.toQueryString())
        .then((response) => {
          return Result.ok<StatisticPollListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<StatisticPollListResponseDto>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_POLL_STATISTICS.LIST)
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(
          new UnknownCause(SERVICE.POLLSTER.STATISTIC.POLL_LIST)
        )
      );
    }
  }
}
