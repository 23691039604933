import { BaseDTO } from "@/core/base/BaseDTO";

export class AddCandidateRequestDto extends BaseDTO {
  data: {
    jobPosting?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
  }
  constructor(
    jobPosting?: number,
    firstName?: string,
    lastName?: string,
    email?: string,
  ) {
    super();
    this.data = {
      jobPosting,
      firstName,
      lastName,
      email
    }
  }
}
