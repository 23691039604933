import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { AddCandidateModel } from "@/domain/interview/add-candidate/model/AddCandidateModel";
import { AddCandidateRequestDto } from "@/domain/interview/add-candidate/dto/AddCandidateRequestDto";

export class AddCandidateMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<AddCandidateModel> {
    try {
      const addCandidateModel: AddCandidateModel = {
        ok: dto.ok,
      };

      return Result.ok(addCandidateModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.ADD_CANDIDATE))
      );
    }
  }

  mapToDTO(domain: AddCandidateModel): Result<AddCandidateRequestDto> {
    try {
      const addCandidateRequest = new AddCandidateRequestDto(
        domain.jobPosting,
        domain.firstName,
        domain.lastName,
        domain.email
      );

      return Result.ok(addCandidateRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.ADD_CANDIDATE))
      );
    }
  }
}
