import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "../store";
import { Actions, Mutations } from "@/presentation/store/enums/StoreEnums";
import { JwtTokenManager } from "@/infrastructure/storage/JwtTokenManager";

import { ROUTE_PAGES } from "@/domain/constants/RoutePages";
import { AES256EncryptionService } from "@/infrastructure/encryption/AES256EncryptionService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "landingPage",
    meta: { title: "Yapay Zeka İş Asistan Web Uygulaması" },
    component: () => import("@/presentation/LandingPage.vue"),
  },
  {
    path: "/app",
    component: () => import("@/presentation/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: { title: "Anasayfa | İş Asistan Web Uygulaması" },
        component: () => import("@/presentation/views/Dashboard.vue"),
      },
      {
        path: "/version",
        name: "version",
        meta: {
          title: "Sürüm Güncellemeleri | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () => import("@/presentation/views/Version.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        meta: { title: "Profilim | İş Asistan Web Uygulaması" },
        component: () => import("@/presentation/views/Profile.vue"),
      },
      {
        path: "/hotel-management/",
        name: "hotelManagement",
        component: () =>
          import("@/presentation/views/modules/hotel-management/Chat.vue"),
        children: [
          {
            path: "/hotel-management/:id",
            name: "hotelManagementHistory",
            meta: {
              title: "Geçmiş | AI Otel Asistanı | İş Asistan Web Uygulaması"
            },
            component: () =>
              import(
                "@/presentation/views/modules/hotel-management/History.vue"
              ),
          },
          {
            path: "/hotel-management/",
            name: "hotelManagementNew",
            meta: {
              title:
                "Yeni Konuşma | AI Otel Asistanı | İş Asistan Web Uygulaması"
            },
            component: () =>
              import(
                "@/presentation/views/modules/hotel-management/NewSession.vue"
              ),
          },
        ],
      },
      {
        path: "/hotel-management/staff-list",
        name: "hotelManagementStaffList",
        meta: {
          title:
            "AI Asistan Modülü Personel Listesi | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () =>
          import("@/presentation/views/modules/hotel-management/StaffList.vue"),
      },
      {
        path: "/hotel-management/staff-history/",
        name: "hotelManagementStaffHistory",
        meta: {
          requiresPermission: "TENANT_ADMIN", shortcode: "OGZA_HOTEL_ASSISTANT_AI"
        },
        component: () =>
          import(
            "@/presentation/views/modules/hotel-management/StaffChatListHistory.vue"
          ),
        children: [
          {
            path: "/hotel-management/staff-list/:staffId",
            name: "hotelManagementStaffChatListHistory",
            meta: {
              title:
                "Personel Konuşma Geçmişleri | AI Otel Asistanı | İş Asistan Web Uygulaması"
            },
            component: () =>
              import(
                "@/presentation/views/modules/hotel-management/StaffChatListPage.vue"
              ),
          },
          {
            path: "/hotel-management/staff-list/:staffId/history/:sessionId",
            name: "hotelManagementStaffChatHistory",
            meta: {
              title:
                "Personel Konuşma Geçmişi | AI Otel Asistanı | İş Asistan Web Uygulaması"
            },
            component: () =>
              import(
                "@/presentation/views/modules/hotel-management/StaffChatHistory.vue"
              ),
          },
        ],
      },
      {
        path: "/module-list",
        name: "moduleList",
        meta: {
          title: "Modüller | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () => import("@/presentation/views/tenant/ModuleList.vue"),
      },
      {
        path: "/module-detail/:id",
        name: "moduleDetail",
        meta: {
          title: "Modül Detayı | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () => import("@/presentation/views/tenant/ModuleDetail.vue"),
      },
      {
        path: "/pollster/create",
        name: "createPoll",
        meta: {
          title: "Anket Oluştur | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () =>
          import("@/presentation/views/modules/pollster/tenant/CreatePoll.vue"),
      },
      {
        path: "/pollster/detail/:id",
        name: "pollDetail",
        meta: {
          title: "Anket Detayları | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () =>
          import("@/presentation/views/modules/pollster/tenant/PollDetail.vue"),
      },
      {
        path: "/pollster/user-assignment",
        name: "userAssignmentPoll",
        meta: {
          title: "Anket Ataması Yap | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () =>
          import(
            "@/presentation/views/modules/pollster/tenant/UserAssignment.vue"
          ),
      },
      {
        path: "/pollster/polls",
        name: "polls",
        meta: {
          title: "Anket Listesi | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () =>
          import("@/presentation/views/modules/pollster/tenant/PollList.vue"),
      },
      {
        path: "/pollster/userPoll",
        name: "userPollDetail",
        meta: {
          title: "Anket Detayları | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () =>
          import(
            "@/presentation/views/modules/pollster/tenant/UserPollDetailPanel.vue"
          ),
        children: [
          {
            path: "/pollster/userPoll/:id/detail",
            component: () =>
              import(
                "@/presentation/views/modules/pollster/tenant/UserPollDetail.vue"
              ),
            props: true,
          },
          {
            path: "/pollster/userPoll/:id/responses",
            component: () =>
              import(
                "@/presentation/views/modules/pollster/tenant/UserPollDetailResponse.vue"
              ),
            props: true,
          },
          {
            path: "/pollster/userPoll/:id/responseDetail",
            component: () =>
              import(
                "@/presentation/views/modules/pollster/tenant/UserPollDetailChatLog.vue"
              ),
            props: true,
          },
        ],
      },
      {
        path: "/pollster/userPolls",
        name: "userPolls",
        meta: {
          title: "Kullanıcı Anketleri | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () =>
          import("@/presentation/views/modules/pollster/tenant/UserPolls.vue"),
      },
      // {
      //   path: "/pollster/userPoll/:id/detail",
      //   name: "userPollDetail",
      //   meta: {
      //     title: "Anket Detayları | İş Asistan Web Uygulaması",
      //     requiresPermission: "TENANT_ADMIN",
      //   },
      //   component: () =>
      //     import(
      //       "@/presentation/views/modules/pollster/tenant/UserPollDetail.vue"
      //     ),
      // },
      {
        path: "/interview/create",
        name: "interview-create",
        meta: {
          title: "Mülakat Oluştur | İş Asistan Web Uygulaması",
          // requiresPermission: "OGZA_ADMIN",
        },
        component: () =>
          import("@/presentation/views/modules/interview/tenant/Create.vue"),
      },
      {
        path: "/interview/userInterviews",
        name: "user-interviews",
        meta: {
          title: "Mülakat Oluştur | İş Asistan Web Uygulaması",
          // requiresPermission: "OGZA_ADMIN",
        },
        component: () =>
          import(
            "@/presentation/views/modules/interview/tenant/UserInterviews.vue"
          ),
      },
      {
        path: "/interview/detail/:id",
        name: "interview-detail",
        meta: {
          title: "Mülakat Detayları | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () =>
          import(
            "@/presentation/views/modules/interview/tenant/InterviewDetail.vue"
          ),
      },
      {
        path: "/interview/add-candidate",
        name: "user-assignment-interview",
        meta: {
          title: "Mülakat Gönder | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () =>
          import(
            "@/presentation/views/modules/interview/tenant/AddCandidate.vue"
          ),
      },
      {
        path: "/interview/interviews",
        name: "interview-list",
        meta: {
          title: "Mülakat Listesi | İş Asistan Web Uygulaması",
          // requiresPermission: "OGZA_ADMIN",
        },
        component: () =>
          import(
            "@/presentation/views/modules/interview/tenant/InterviewList.vue"
          ),
      },
      /// YENİ OLAN SAYFA TASARIMLARI
      {
        path: "/interview/new-interview",
        name: "new-create-interview",
        meta: {
          title: "Yeni Mülakat Oluştur | İş Asistan Web Uygulaması",
          // requiresPermission: "OGZA_ADMIN",
        },
        component: () =>
          import(
            "@/presentation/views/modules/interview/tenant/NewCreateInterview.vue"
          ),
      },
      {
        path: "/interview/interview-list",
        name: "new-interview-list",
        meta: {
          title: "Açık Pozisyon Listesi | İş Asistan Web Uygulaması",
          // requiresPermission: "OGZA_ADMIN",
        },
        component: () =>
          import(
            "@/presentation/views/modules/interview/tenant/NewInterviewList.vue"
          ),
      },
      {
        path: "/interview/jobposting-detail",
        name: "new-jobposting-detail",
        meta: {
          title: "Açık Pozisyon Detayları | İş Asistan Web Uygulaması",
          // requiresPermission: "OGZA_ADMIN",
        },
        component: () =>
          import(
            "@/presentation/views/modules/interview/tenant/NewJobPostingDetail.vue"
          ),
      },
      {
        path: "/interview/interview-detail",
        name: "new-interview-detail",
        meta: {
          title: "Mülakat Detayları | İş Asistan Web Uygulaması",
          // requiresPermission: "OGZA_ADMIN",
        },
        component: () =>
          import(
            "@/presentation/views/modules/interview/tenant/NewInterviewDetail.vue"
          ),
      },
      {
        path: "/interview/candidate-detail",
        name: "new-candidate-detail",
        meta: {
          title: "Aday Detayları | İş Asistan Web Uygulaması",
          // requiresPermission: "OGZA_ADMIN",
        },
        component: () =>
          import(
            "@/presentation/views/modules/interview/tenant/NewCandidateDetail.vue"
          ),
      },
      {
        path: "/interview/invite-candidate",
        name: "new-invite-candidate",
        meta: {
          title: "Aday Davet Et | İş Asistan Web Uygulaması",
          // requiresPermission: "OGZA_ADMIN",
        },
        component: () =>
          import(
            "@/presentation/views/modules/interview/tenant/NewInviteCandidate.vue"
          ),
      },
      {
        path: "/interview/userIntervieww",
        name: "user-interview-detail3",
        meta: {
          title: "Mülakat Detayları | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () =>
          import(
            "@/presentation/views/modules/interview/tenant/UserInterviewDetailPanel3.vue"
          ),
        children: [
          {
            path: "/interview/userIntervieww/:id/detail",
            component: () =>
              import(
                "@/presentation/views/modules/interview/tenant/UserInterviewDetail3.vue"
              ),
            props: true,
          },
          {
            path: "/interview/userIntervieww/:id/responses",
            component: () =>
              import(
                "@/presentation/views/modules/interview/tenant/UserInterviewDetailResponse3.vue"
              ),
            props: true,
          },
          {
            path: "/interview/userIntervieww/:id/responseDetail",
            component: () =>
              import(
                "@/presentation/views/modules/interview/tenant/UserInterviewDetailChatLog3.vue"
              ),
            props: true,
          },
        ],
      },
      {
        path: "/interview/userInterview",
        name: "user-interview-detail",
        meta: {
          title: "Mülakat Detayları | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () =>
          import(
            "@/presentation/views/modules/interview/tenant/UserInterviewDetailPanel.vue"
          ),
        children: [
          {
            path: "/interview/userInterview/:id/detail",
            component: () =>
              import(
                "@/presentation/views/modules/interview/tenant/UserInterviewDetail.vue"
              ),
            props: true,
          },
          {
            path: "/interview/userInterview/:id/responses",
            component: () =>
              import(
                "@/presentation/views/modules/interview/tenant/UserInterviewDetailResponse.vue"
              ),
            props: true,
          },
        ],
      },
      {
        path: "/account-status",
        name: "account-status",
        meta: {
          title: "Hesap Durumu | İş Asistan Web Uygulaması",
          requiresPermission: "OGZA_ADMIN",
        },
        component: () =>
          import("@/presentation/views/tenant/AccountStatus.vue"),
      },
      {
        path: "/account-types",
        name: "accountTypes",
        meta: {
          title: "Hesap Tipleri | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () => import("@/presentation/views/tenant/AccountTypes.vue"),
      },
      {
        path: "/staff-list",
        name: "staffList",
        meta: {
          title: "Personel Listesi | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () => import("@/presentation/views/tenant/StaffList.vue"),
      },
      {
        path: "/invite-staff",
        name: "inviteStaff",
        meta: {
          title: "Personel Davet Et | İş Asistan Web Uygulaması",
          requiresPermission: "TENANT_ADMIN",
        },
        component: () => import("@/presentation/views/tenant/InviteStaff.vue"),
      },
      // Ogza
      {
        path: "/all-module",
        name: "allModule",
        meta: {
          title: "Modül Listesi | İş Asistan Web Uygulaması",
          requiresPermission: "OGZA_ADMIN",
        },
        component: () => import("@/presentation/views/ogza/ModuleList.vue"),
      },
      {
        path: "/companies",
        name: "companies",
        meta: {
          title: "Şirketler | İş Asistan Web Uygulaması",
          requiresPermission: "OGZA_ADMIN",
        },
        component: () => import("@/presentation/views/ogza/Companies.vue"),
      },
      {
        path: "/create-company",
        name: "createCompany",
        meta: {
          title: "Şirket Oluştur | İş Asistan Web Uygulaması",
          requiresPermission: "OGZA_ADMIN",
        },
        component: () => import("@/presentation/views/ogza/CreateCompany.vue"),
      },
      {
        path: "/create-module",
        name: "createModule",
        meta: {
          title: "Modül Oluştur | İş Asistan Web Uygulaması",
          requiresPermission: "OGZA_ADMIN",
        },
        component: () => import("@/presentation/views/ogza/CreateModule.vue"),
      },
      {
        path: "/chat-gpt-settings",
        name: "chatGptSettings",
        meta: {
          title: "ChatGpt Ayarları | İş Asistan Web Uygulaması",
          requiresPermission: "OGZA_ADMIN",
        },
        component: () =>
          import("@/presentation/views/ogza/ChatGptSettings.vue"),
      },
    ],
  },
  {
    path: "/guest",
    component: () => import("@/presentation/layout/Guest.vue"),
    children: [
      {
        path: "/interview",
        name: "doInterview",
        meta: {
          title: "Mülakat | İş Asistan Web Uygulaması",
          // requiresPermission: "TENANT_ADMIN",
        },
        component: () =>
          import("@/presentation/views/modules/interview/guest/Interview.vue"),
      },
      {
        path: "/start-to-interview",
        name: "startToInterview",
        meta: {
          title: "Mülakata Başla | İş Asistan Web Uygulaması",
          // requiresPermission: "TENANT_ADMIN",
        },
        component: () =>
          import(
            "@/presentation/views/modules/interview/guest/StartInterview.vue"
          ),
      },
      //https://dev.assistant.ogzatech.com/pollster/16?token=21a360298ba70758e8e4c0f21ae619b5
      {
        path: "/pollster/:id",
        name: "doPoll",
        meta: {
          title: "Anket Yap | İş Asistan Web Uygulaması",
        }, // TENANT_ADMIN OGZA_ADMIN
        component: () =>
          import("@/presentation/views/modules/pollster/guest/Pollster.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/presentation/layout/Auth.vue"),
    children: [
      {
        path: "/login",
        name: "login",
        meta: { title: "Giriş Yap | İş Asistan Web Uygulaması" },
        component: () => import("@/presentation/views/auth/Login.vue"),
      },
      {
        path: "/set-profile-info",
        name: "setProfileInfo",
        meta: { title: "Profil Oluştur | İş Asistan Web Uygulaması" },
        component: () => import("@/presentation/views/auth/SetProfileInfo.vue"),
      },
      {
        path: "/companies",
        name: "companies",
        meta: { title: "Şirketler | İş Asistan Web Uygulaması" },
        component: () => import("@/presentation/views/auth/Companies.vue"),
      },
      {
        path: "/create-company",
        name: "createCompany",
        meta: { title: "Şirket Oluştur | İş Asistan Web Uygulaması" },
        component: () => import("@/presentation/views/CreateCompany.vue"),
      },
      {
        path: "/invites/accept",
        name: "acceptInvite",
        meta: { title: "Daveti Kabul Et | İş Asistan Web Uygulaması" },
        component: () => import("@/presentation/views/auth/AcceptInvite.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    meta: { title: "400 - Sayfa Bulunamadı" },
    component: () => import("@/presentation/views/error/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    meta: { title: "500 - Bulunamadı" },
    component: () => import("@/presentation/views/error/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const jwtTokenManager = new JwtTokenManager();

router.beforeEach((to, from, next) => {
  document.title = (to.meta.title as string) || "İş Asistan Web Uygulaması";

  const encryptedUserRole = localStorage.getItem("roleCode");
  const decryptUserRole = AES256EncryptionService.decrypt(encryptedUserRole); // kullanıcının rolu

  // const purchasedProducts = JSON.parse(localStorage.getItem("purchasedProducts") || "[]");

  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  if (to.name === ROUTE_PAGES.SIGN_IN) {
    const tokenQuery = to.query.token;

    if (jwtTokenManager.getToken()) {
      store.dispatch(Actions.VERIFY_AUTH).then(() => {
        if (tokenQuery) {
          next({
            name: ROUTE_PAGES.ACCEPT_INVITE,
            query: { token: tokenQuery },
          });
        } else {
          next({ name: ROUTE_PAGES.DASHBOARD });
        }
      });
      return;
    }
    
    next();
    return;
  } else if (
    to.name === ROUTE_PAGES.LANDING_PAGE ||
    to.name === ROUTE_PAGES.POLLSTER ||
    to.name === ROUTE_PAGES.DO_INTERVIEW ||
    to.name === ROUTE_PAGES.START_INTERVIEW ||
    to.name === ROUTE_PAGES.DO_POLL
  ) {
    next();
  } else if (to.name === ROUTE_PAGES.ACCEPT_INVITE) {
    if (!jwtTokenManager.getToken()) {
      next({ name: ROUTE_PAGES.SIGN_IN, query: { token: to.query.token } });
    } else {
      next();
    }
  } else {
    if (jwtTokenManager.getToken()) {
      if (to.meta.requiresPermission) {
        if (decryptUserRole == to.meta.requiresPermission) {
          // const shortCode = to.meta.shortcode;
          // if (shortCode) {
          //   if (purchasedProducts.includes(shortCode)) {
          //     next();
          //     return;
          //   } else {
          //     next({ name: ROUTE_PAGES.FORBIDDEN });
          //     return;
          //   }
          // }
          next();
          return;
        } else {
          next({ name: ROUTE_PAGES.FORBIDDEN });
          return;
        }
      } else {
        next();
        return;
      }
    } else {
      next({ name: ROUTE_PAGES.SIGN_IN });
      return;
    }
  }
});

export default router;
