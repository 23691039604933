import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IJobPostingListService } from "@/domain/interview/job-posting-list/service/IJobPostingListService";
import { JobPostingListRequestDto } from "@/domain/interview/job-posting-list/dto/JobPostingListRequestDto";
import { JobPostingListResponseDto } from "@/domain/interview/job-posting-list/dto/JobPostingListResponseDto";

export default class JobPostingListService implements IJobPostingListService {
  async getList(
    request: JobPostingListRequestDto
  ): Promise<Result<JobPostingListResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.INTERVIEW + request.toQueryString())
        .then((response) => {
          return Result.ok<JobPostingListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<JobPostingListResponseDto>(
            new BadRequestException(
              new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_INTERVIEW_LIST))
            )
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(new UnknownCause(SERVICE.INTERVIEW.INTERVIEW_LIST))
      );
    }
  }
}
