import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { IPollDetailRepository } from "@/domain/pollster/poll-detail/repository/IPollDetailRepository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import PollDetailService from "../service/PollDetailService";
import { PollDetailRequestDto } from "@/domain/pollster/poll-detail/dto/PollDetailRequestDto";
import { PollDetailResponseDto } from "@/domain/pollster/poll-detail/dto/PollDetailResponseDto";

const pollDetailService = new PollDetailService();

export class PollDetailRepository implements IPollDetailRepository {
  async getPollDetail(request: PollDetailRequestDto): Promise<Result<PollDetailResponseDto>> {
    try {
      return await pollDetailService
        .get(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<PollDetailResponseDto>(response.getValue());
          } else {
            return Result.fail<PollDetailResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>(new BadRequestException(error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.POLLSTER.POLL_DETAIL)));
    }
  }
}
