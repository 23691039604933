import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { ProductModelSettingModel } from "@/domain/product/product-list/model/ProductModelSettingModel";
import { ProductPackageModel } from "@/domain/product/product-list/model/ProductPackageModel";
import { ProductFAQModel } from "@/domain/product/product-list/model/ProductFAQModel";
import { GetPurchasedProductsResponseDto } from "@/domain/product/get-purchased-products/dto/GetPurchasedProductsResponseDto";
import { GetPurchasedProductsModel } from "@/domain/product/get-purchased-products/model/GetPurchasedProductsModel";
import { GetPurchasedProductsRequestDto } from "@/domain/product/get-purchased-products/dto/GetPurchasedProductsRequestDto";
import { useI18n } from "vue-i18n";

export class GetPurchasedProductsMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: GetPurchasedProductsResponseDto): Result<GetPurchasedProductsModel[]> {
    try {
      const purchasedProducts: GetPurchasedProductsModel[] = [];

      if (dto.data.length > 0) {
        for (const item of dto.data) {
          const productPackages: ProductPackageModel[] =
            item.attributes.productPackages?.data.map((pkg) => {
              const productModelSettings: ProductModelSettingModel[] =
                pkg.attributes.productModelSettings?.data.map((setting) => {
                  return new ProductModelSettingModel(
                    Number(setting.id),
                    setting.attributes.name,
                    setting.attributes.description,
                    setting.attributes.selected
                  );
                });

              return new ProductPackageModel(
                productModelSettings,
                Number(pkg.id),
                pkg.attributes.name,
                Number(pkg.attributes.price),
                Number(pkg.attributes.currency),
                pkg.attributes.description,
                Number(pkg.attributes.period),
                Number(pkg.attributes.quota),
                pkg.attributes.purchased
              );
            });

          const productFAQs: ProductFAQModel[] =
            item.attributes.productFAQs?.data.map((pfaq) => {
              return new ProductFAQModel(
                Number(pfaq.id),
                pfaq.attributes.question,
                pfaq.attributes.answer
              );
            });

          const purchasedProductsModel: GetPurchasedProductsModel = {
            product: {
              id: Number(item.id),
              name: item.attributes.name,
              shortCode: item.attributes.shortCode,
              description: item.attributes.description,
              productPackages: productPackages,
              productFAQs: productFAQs
            },
          };

          purchasedProducts.push(purchasedProductsModel);
        }
        return Result.ok(purchasedProducts);
      }
      return Result.ok(purchasedProducts);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.PRODUCT.GET_PURCHASED_PRODUCT))
      );
    }
  }
  
  mapToDTO(domain: GetPurchasedProductsModel): Result<GetPurchasedProductsRequestDto> {
    try {
      const i18n = useI18n();
      /*
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );

      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();
*/
      const getPurchasedProductsRequest = new GetPurchasedProductsRequestDto(i18n.locale.value)

      return Result.ok(getPurchasedProductsRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.PRODUCT.GET_PURCHASED_PRODUCT))
      );
    }
  }
}
