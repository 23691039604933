import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import PollSessionDetailService from "../service/PollSessionDetailService";
import { IPollSessionDetailRepository } from "@/domain/pollster/poll-statistic/poll-session-detail/repository/IPollSessionDetailRepository";
import { PollSessionDetailRequestDto } from "@/domain/pollster/poll-statistic/poll-session-detail/dto/PollSessionDetailRequestDto";
import { PollSessionDetailResponseDto } from "@/domain/pollster/poll-statistic/poll-session-detail/dto/PollSessionDetailResponseDto";

const getPollSessionDetailService = new PollSessionDetailService();

export class PollSessionDetailRepository implements IPollSessionDetailRepository {
  async getPollSessionDetail(request: PollSessionDetailRequestDto): Promise<Result<PollSessionDetailResponseDto>> {
    try {
      return await getPollSessionDetailService
        .get(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<PollSessionDetailResponseDto>(response.getValue());
          } else {
            return Result.fail<PollSessionDetailResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>(new BadRequestException(error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.POLLSTER.STATISTIC.POLL_SESSION_DETAIL)));
    }
  }
}
