import { BaseDomain } from "@/core/base/BaseDomain";
import { EmotionAnalysisModel } from "../../send-message/model/EmotionAnalysisModel";

export class RetryAnswerModel extends BaseDomain {
  messageId?: number;
  owner?: string;
  text?: string;
  createdAt?: Date;
  isLiked?: any;
  emotionAnalysis?: EmotionAnalysisModel;

  constructor(
    messageId?: number,
    owner?: string,
    text?: string,
    createdAt?: Date,
    isLiked?: any,
    emotionAnalysis?: EmotionAnalysisModel
  ) {
    super();
    this.messageId = messageId;
    this.owner = owner;
    this.text = text;
    this.createdAt = createdAt;
    this.isLiked = isLiked;
    this.emotionAnalysis = emotionAnalysis;
  }
}
