import { ICountryListUseCase } from "@/domain/country/country-list/usecase/ICountryListService";
import { CountryListRepository } from "../repository/CountryListRepository";
import { CountryListModel } from "@/domain/country/country-list/model/CountryListModel";
import { Result } from "@/core/utility/ResultType";
import { CountryListMapper } from "../mapper/CountryListMapper";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/application/constants/Usecase";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ICountryListRepository } from "@/domain/country/country-list/repository/ICountryListRepository";

export class CountryListUseCase implements ICountryListUseCase {
  countryListRepository : ICountryListRepository = new CountryListRepository();
  countryListMapper = new CountryListMapper();

  constructor() {
  }

  async execute(model: CountryListModel): Promise<Result<CountryListModel>> {
    try {
      const dto = this.countryListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<CountryListModel>(dto.getError());
      } else {
        const response = await this.countryListRepository.countryList(dto);

        if (response.isSuccess) {
          const domain = this.countryListMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<CountryListModel>(domain.getValue());
        } else {
          return Result.fail<CountryListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<CountryListModel>(
        new UseCaseException(new UnknownCause(USECASE.COUNTRY_LIST))
      );
    }
  }
}
