import { DeleteStaffRepository } from "../repository/DeleteStaffRepository";
import { DeleteStaffModel } from "@/domain/staff/delete-staff/model/DeleteStaffModel";
import { IDeleteStaffUseCase } from "@/domain/staff/delete-staff/usecase/IDeleteStaffUseCase";
import { DeleteStaffMapper } from "../mapper/DeleteStaffMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IDeleteStaffRepository } from "@/domain/staff/delete-staff/repository/IDeleteStaffRepository";

export class DeleteStaffUseCase implements IDeleteStaffUseCase {
  deleteStaffRepository: IDeleteStaffRepository = new DeleteStaffRepository();
  deleteStaffMapper = new DeleteStaffMapper();

  constructor() {
  }

  async execute(model: DeleteStaffModel): Promise<Result<DeleteStaffModel>> {
    try {
      const dto = this.deleteStaffMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<DeleteStaffModel>(dto.getError());
      } else {
        const response = await this.deleteStaffRepository.deleteStaff(dto);

        if (response.isSuccess) {
          const domain = this.deleteStaffMapper.mapToDomain(
            response.getValue()
          );

          return Result.ok<DeleteStaffModel>(domain.getValue());
        } else {
          return Result.fail<DeleteStaffModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<DeleteStaffModel>(
        new UseCaseException(new UnknownCause(USECASE.STAFF.DELETE_STAFF))
      );
    }
  }
}
