
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ICreateJobPostingUseCase } from "@/domain/interview/create-job-posting/usecase/ICreateJobPostingUsecase";
import { ICreateJobPostingRepository } from "@/domain/interview/create-job-posting/repository/ICreateJobPostingRepository";
import { CreateJobPostingMapper } from "../mapper/CreateJobPostingMapper";
import { CreateJobPostingRepository } from "../repository/CreateJobPostingRepository";
import { CreateJobPostingModel } from "@/domain/interview/create-job-posting/model/CreateJobPostingModel";

export class CreateJobPostingUsecase implements ICreateJobPostingUseCase {
  createJobPostingRepository : ICreateJobPostingRepository = new CreateJobPostingRepository();
  createJobPostingMapper = new CreateJobPostingMapper();

  constructor() {
  }

  async execute(model: CreateJobPostingModel): Promise<Result<CreateJobPostingModel>> {
    try {
      const dto = this.createJobPostingMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<CreateJobPostingModel>(dto.getError());
      } else {
        const response = await this.createJobPostingRepository.createJobPosting(dto);

        if (response.isSuccess) {
          const domain = this.createJobPostingMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<CreateJobPostingModel>(domain.getValue());
        } else {
          return Result.fail<CreateJobPostingModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<CreateJobPostingModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.CREATE_INTERVIEW))
      );
    }
  }
}
