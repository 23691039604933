import { DeletePollRepository } from "../repository/DeletePollRepository";
import { DeletePollModel } from "@/domain/pollster/delete-poll/model/DeletePollModel";
import { IDeletePollUseCase } from "@/domain/pollster/delete-poll/usecase/IDeletePollUseCase";
import { DeletePollMapper } from "../mapper/DeletePollMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IDeletePollRepository } from "@/domain/pollster/delete-poll/repository/IDeletePollRepository";

export class DeletePollUseCase implements IDeletePollUseCase {
  deletePollRepository : IDeletePollRepository = new DeletePollRepository();
  deletePollMapper = new DeletePollMapper();

  constructor() {
  }

  async execute(model: DeletePollModel): Promise<Result<DeletePollModel>> {
    try {
      const dto = this.deletePollMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<DeletePollModel>(dto.getError());
      } else {
        const response = await this.deletePollRepository.deletePoll(dto);

        if (response.isSuccess) {
          const domain = this.deletePollMapper.mapToDomain(
            response.getValue()
          );

          return Result.ok<DeletePollModel>(domain.getValue());
        } else {
          return Result.fail<DeletePollModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<DeletePollModel>(
        new UseCaseException(new UnknownCause(USECASE.POLLSTER.DELETE_POLL))
      );
    }
  }
}
