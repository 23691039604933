import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { AcceptInviteModel } from "@/domain/staff/invite-staff/accept/model/AcceptInviteModel";
import { AcceptInviteRequestDto } from "@/domain/staff/invite-staff/accept/dto/AcceptInviteRequestDto";

export class AcceptInviteMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<AcceptInviteModel> {
    try {
        const acceptInviteModel: AcceptInviteModel = {
          ok: dto.ok,
        };
        return Result.ok(acceptInviteModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.STAFF.ACCEPT_INVITE))
      );
    }
  }

  mapToDTO(domain: AcceptInviteModel): Result<AcceptInviteRequestDto> {
    try {
      const acceptInviteRequest = new AcceptInviteRequestDto(
        domain.token
      );

      return Result.ok(acceptInviteRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.STAFF.ACCEPT_INVITE))
      );
    }
  }
}
