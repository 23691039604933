import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { PollResponseListModel } from "@/domain/pollster/poll-statistic/poll-response-list/model/PollResponseListModel";
import { PollResponseListResponseDto } from "@/domain/pollster/poll-statistic/poll-response-list/dto/PollResponseListResponseDto";
import { PollResponseListRequestDto } from "@/domain/pollster/poll-statistic/poll-response-list/dto/PollResponseListRequestDto";

export class PollResponseListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(
    dto: PollResponseListResponseDto
  ): Result<PollResponseListModel[]> {
    try {
      const pollResponseListModel: PollResponseListModel[] = [];

      if (dto.data.length > 0) {
        for (const item of dto.data) {
          const options =
            item.attributes.surveyQuestionOptions?.data.map((option) => ({
              optionText: option.attributes.optionText,
            })) || [];

          let response = "";

          if (item.attributes.surveyQuestionResponse.data) {
            const responseAttributes =
              item.attributes.surveyQuestionResponse.data.attributes;

            if (responseAttributes.responseText) {
              response = responseAttributes.responseText;
            } else if (
              responseAttributes.singleChoice.data &&
              responseAttributes.singleChoice.data.attributes.optionText
            ) {
              response =
                responseAttributes.singleChoice.data.attributes.optionText.toString();
            } else if (responseAttributes.multipleChoices?.data.length > 0) {
              response = responseAttributes.multipleChoices.data
                .map((choice) => choice.attributes.optionText)
                .join(", ");
            }
          }

          const pollResponseModel: PollResponseListModel = {
            id: item.id,
            questionText: item.attributes.questionText,
            questionType: item.attributes.questionType,
            surveyQuestionOptions: options,
            surveyQuestionResponse: response,
          };

          pollResponseListModel.push(pollResponseModel);
        }
        return Result.ok(pollResponseListModel);
      }
      return Result.ok(pollResponseListModel);
    } catch (error) {
      return Result.fail(
        new MapperException(
          new UnknownCause(MAPPER.POLLSTER.STATISTIC.POLL_RESPONSE_LIST)
        )
      );
    }
  }

  mapToDTO(domain: PollResponseListModel): Result<PollResponseListRequestDto> {
    try {
      const pollRequest: PollResponseListRequestDto = {
        id: domain.id,
      };

      return Result.ok(pollRequest);
    } catch {
      return Result.fail(
        new MapperException(
          new UnknownCause(MAPPER.POLLSTER.STATISTIC.POLL_RESPONSE_LIST)
        )
      );
    }
  }
}
