
import { Result } from "@/core/utility/ResultType";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/application/constants/Usecase";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IGetPurchasedProductsUseCase } from "@/domain/product/get-purchased-products/usecase/IGetPurchasedProductsUsecase";
import { GetPurchasedProductsRepository } from "../repository/GetPurchasedProductsRepository";
import { GetPurchasedProductsMapper } from "../mapper/GetPurchasedProductsMapper";
import { GetPurchasedProductsModel } from "@/domain/product/get-purchased-products/model/GetPurchasedProductsModel";
import { IGetPurchasedProductsRepository } from "@/domain/product/get-purchased-products/repository/IGetPurchasedProductsRepository";

export class GetPurchasedProductsUseCase implements IGetPurchasedProductsUseCase {
  purchasedProductsRepository : IGetPurchasedProductsRepository = new GetPurchasedProductsRepository();
  purchasedProductsMapper = new GetPurchasedProductsMapper();

  constructor() {
  }

  async execute(model: GetPurchasedProductsModel): Promise<Result<GetPurchasedProductsModel>> {
    try {
      const dto = this.purchasedProductsMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<GetPurchasedProductsModel>(dto.getError());
      } else {
        const response = await this.purchasedProductsRepository.purchasedProducts(dto);

        if (response.isSuccess) {
          const domain = this.purchasedProductsMapper.mapToDomain(response.getValue());
          return Result.ok<GetPurchasedProductsModel>(domain.getValue());
        } else {
          return Result.fail<GetPurchasedProductsModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<GetPurchasedProductsModel>(
        new UseCaseException(new UnknownCause(USECASE.PRODUCT.GET_PURCHASED_PRODUCT))
      );
    }
  }
}
