
import { Result } from "@/core/utility/ResultType";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/application/constants/Usecase";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { PollMessageListRepository } from "../repository/PollMessageListRepository";
import { PollMessageListMapper } from "../mapper/PollMessageListMapper";
import { IPollMessageListUseCase } from "@/domain/pollster/poll-statistic/poll-message-list/usecase/IPollMessageListUsecase";
import { IPollMessageListRepository } from "@/domain/pollster/poll-statistic/poll-message-list/repository/IPollMessageListRepository";
import { PollMessageListModel } from "@/domain/pollster/poll-statistic/poll-message-list/model/PollMessageListModel";

export class PollMessageListUseCase implements IPollMessageListUseCase {
  pollMessageListRepository : IPollMessageListRepository = new PollMessageListRepository();
  pollMessageListMapper = new PollMessageListMapper();

  constructor() {
  }

  async execute(model: PollMessageListModel): Promise<Result<PollMessageListModel>> {
    try {
      const dto = this.pollMessageListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<PollMessageListModel>(dto.getError());
      } else {
        const response = await this.pollMessageListRepository.getPollMessageList(dto);

        if (response.isSuccess) {
          const domain = this.pollMessageListMapper.mapToDomain(response.getValue());
          return Result.ok<PollMessageListModel>(domain.getValue());
        } else {
          return Result.fail<PollMessageListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<PollMessageListModel>(
        new UseCaseException(new UnknownCause(USECASE.POLLSTER.STATISTIC.POLL_MESSAGE_LIST))
      );
    }
  }
}
