import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";
import { JobPostingListResponseDto } from "@/domain/interview/job-posting-list/dto/JobPostingListResponseDto";
import { JobPostingListModel } from "@/domain/interview/job-posting-list/model/JobPostingListModel";
import { JobPostingListRequestDto } from "@/domain/interview/job-posting-list/dto/JobPostingListRequestDto";

const paginationMapper = new PaginationMapper();

export class JobPostingListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: JobPostingListResponseDto): Result<JobPostingListModel[]> {
    try {
      const jobPostingList: JobPostingListModel[] = [];
      if (dto.data)
        if (dto.data.length > 0) {
          for (const item of dto.data) {
            const jobPostingListModel: JobPostingListModel = {
              jobPosting: {
                jobPostingId: Number(item.id),
                title: item.attributes.title,
                description: item.attributes.description,
                position: item.attributes.position,
                interview: {
                  interviewId: item.attributes.interview
                    ? item.attributes.interview.data.id
                    : undefined,
                  title: item.attributes.interview
                    ? item.attributes.interview.data.attributes.title
                    : undefined,
                  description: item.attributes.interview
                    ? item.attributes.interview.data.attributes.description
                    : undefined,
                  startDate: item.attributes.interview
                    ? item.attributes.interview.data.attributes.startDate
                    : undefined,
                  endDate: item.attributes.interview
                    ? item.attributes.interview.data.attributes.endDate
                    : undefined,
                  status: item.attributes.interview
                    ? item.attributes.interview.data.attributes.status
                    : undefined,
                },
              },
              page: 0,
              pageSize: 0,
              pagination: dto.meta?.pagination,
            };

            jobPostingList.push(jobPostingListModel);
          }
          return Result.ok(jobPostingList);
        }
      return Result.ok(jobPostingList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.INTERVIEW_LIST))
      );
    }
  }

  mapToDTO(domain: JobPostingListModel): Result<JobPostingListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const jobPostingListRequest = new JobPostingListRequestDto(pagiRequest, domain.jobPosting.interview.status);

      return Result.ok(jobPostingListRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.INTERVIEW_LIST))
      );
    }
  }
}
