import { BaseDTO } from "@/core/base/BaseDTO";
import { InterviewModel } from "../../model/InterviewModel";

export class CreateJobPostingRequestDto extends BaseDTO {
  data: {
    title?: string;
    description?: string;
    position?: string;
    interview?: InterviewModel;
  }
  constructor(
    title?: string,
    description?: string,
    position?: string,
    interview?: InterviewModel,
  ) {
    super();
    this.data = {
      title,
      description,
      position,
      interview,
    }
  }
}
