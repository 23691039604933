import { InviteStaffRepository } from "../repository/InviteStaffRepository";
import { InviteStaffModel } from "@/domain/staff/invite-staff/model/InviteStaffModel";
import { IInviteStaffUseCase } from "@/domain/staff/invite-staff/usecase/IInviteStaffUseCase";
import { InviteStaffMapper } from "../mapper/InviteStaffMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IInviteStaffRepository } from "@/domain/staff/invite-staff/repository/IInviteStaffRepository";

export class InviteStaffUseCase implements IInviteStaffUseCase {
  inviteStaffRepository: IInviteStaffRepository = new InviteStaffRepository();
  inviteStaffMapper = new InviteStaffMapper();

  constructor() {
  }

  async execute(model: InviteStaffModel): Promise<Result<InviteStaffModel>> {
    try {
      const dto = this.inviteStaffMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<InviteStaffModel>(dto.getError());
      } else {
        const response = await this.inviteStaffRepository.inviteUser(dto);

        if (response.isSuccess) {
          const domain = this.inviteStaffMapper.mapToDomain(
            response.getValue()
          );

          return Result.ok<InviteStaffModel>(domain.getValue());
        } else {
          return Result.fail<InviteStaffModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<InviteStaffModel>(
        new UseCaseException(new UnknownCause(USECASE.STAFF.INVITE_STAFF))
      );
    }
  }
}
