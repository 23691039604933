import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { GetProductRequestDto } from "@/domain/product/get-product/dto/GetProductRequestDto";
import { GetProductModel } from "@/domain/product/get-product/model/GetProductModel";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";

export class GetProductMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<GetProductModel> {
    try {
        const getProductModel: GetProductModel = {
          ok: dto.ok,
        };
        return Result.ok(getProductModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.PRODUCT.GET_PRODUCT))
      );
    }
  }

  mapToDTO(domain: GetProductModel): Result<GetProductRequestDto> {
    try {
      const getProductRequest: GetProductRequestDto = {
        shortCode: domain.shortCode,
      };

      return Result.ok(getProductRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.PRODUCT.GET_PRODUCT))
      );
    }
  }
}
