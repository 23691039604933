
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IFileUploadUseCase } from "@/domain/file-upload/usecase/IFileUploadUsecase";
import { FileUploadRepository } from "../repository/FileUploadRepository";
import { FileUploadMapper } from "../mapper/FileUploadMapper";
import { FileUploadModel } from "@/domain/file-upload/model/FileUploadModel";
import { IFileUploadRepository } from "@/domain/file-upload/repository/IFileUploadRepository";

export class FileUploadUsecase implements IFileUploadUseCase {
  fileUploadRepository : IFileUploadRepository = new FileUploadRepository();
  fileUploadMapper = new FileUploadMapper();

  constructor() {
  }

  async execute(model: FileUploadModel): Promise<Result<FileUploadModel>> {
    try {

      const dto = this.fileUploadMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<FileUploadModel>(dto.getError());
      } else {
        const response = await this.fileUploadRepository.fileUpload(dto);

        if (response.isSuccess) {
          const domain = this.fileUploadMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<FileUploadModel>(domain.getValue());
        } else {
          return Result.fail<FileUploadModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<FileUploadModel>(
        new UseCaseException(new UnknownCause(USECASE.FILE_UPLOAD))
      );
    }
  }
}
