import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { ProductListUseCase } from "@/application/product/product-list/usecase/ProductListUseCase";
import { ProductListModel } from "@/domain/product/product-list/model/ProductListModel";
import { BuyProductUsecase } from "@/application/product/buy-product/usecase/BuyProductUseCase";
import { BuyProductModel } from "@/domain/product/buy-product/model/BuyProductModel";
import { UpdateProductModelSettingUseCase } from "@/application/product/update-product-model-setting/usecase/UpdateProductModelSettingUseCase";
import { UpdateProductModelSettingModel } from "@/domain/product/update-product-model-setting/model/UpdateProductModelSettingModel";
import { GetPurchasedProductsUseCase } from "@/application/product/get-purchased-products/usecase/GetPurchasedProductsUseCase";
import { GetProductModel } from "@/domain/product/get-product/model/GetProductModel";
import { GetProductUseCase } from "@/application/product/get-product/usecase/GetProductUseCase";

export function createProductController() {
  const productListUseCase = new ProductListUseCase();
  const buyProductUseCase = new BuyProductUsecase();
  const getProductUseCase = new GetProductUseCase();
  const updateProductModelSettingUseCase =
    new UpdateProductModelSettingUseCase();
  const getPurchasedProductsUseCase = new GetPurchasedProductsUseCase();
  return new ProductController(
    productListUseCase,
    buyProductUseCase,
    getProductUseCase,
    updateProductModelSettingUseCase,
    getPurchasedProductsUseCase
  );
}

export class ProductController implements IController {
  constructor(
    private productListUseCase: ProductListUseCase,
    private buyProductUseCase: BuyProductUsecase,
    private getProductUseCase: GetProductUseCase,
    private updateProductModelSettingUseCase: UpdateProductModelSettingUseCase,
    private getPurchasedProductsUseCase: GetPurchasedProductsUseCase
  ) {}

  async productList(
    productListModel: ProductListModel
  ): Promise<Result<ProductListModel>> {
    const response = await this.productListUseCase.execute(productListModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<ProductListModel>(response.getError());
    }
  }

  async buyProduct(
    buyProductModel: BuyProductModel
  ): Promise<Result<BuyProductModel>> {
    const response = await this.buyProductUseCase.execute(buyProductModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<BuyProductModel>(response.getError());
    }
  }

  async getProduct(
    getProductModel: GetProductModel
  ): Promise<Result<GetProductModel>> {
    const response = await this.getProductUseCase.execute(getProductModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<GetProductModel>(response.getError());
    }
  }

  async updateProductModelSetting(
    updateProductModelSettingModel: UpdateProductModelSettingModel
  ): Promise<Result<UpdateProductModelSettingModel>> {
    const response = await this.updateProductModelSettingUseCase.execute(
      updateProductModelSettingModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<UpdateProductModelSettingModel>(response.getError());
    }
  }

  async getPurchasedProducts(
    productListModel: ProductListModel
  ): Promise<Result<ProductListModel>> {
    const response = await this.getPurchasedProductsUseCase.execute(
      productListModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<ProductListModel>(response.getError());
    }
  }
}
