import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IInviteUserService } from "@/domain/pollster/Invite-user/service/IInviteUserService";
import { InviteUserRequestDto } from "@/domain/pollster/Invite-user/dto/InviteUserRequestDto";
import { InviteUserResponseDto } from "@/domain/pollster/Invite-user/dto/InviteUserResponseDto";

export default class InviteUserService implements IInviteUserService {
  async invite(
    request: InviteUserRequestDto
  ): Promise<Result<InviteUserResponseDto>> {
    try {
      return await instance
        .post(API_ROUTE.INVITE_USER, request)
        .then((response) => {
          return Result.ok<InviteUserResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<InviteUserResponseDto>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_INVITE_USER)
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<InviteUserResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.POLLSTER.INVITE_USER))
      );
    }
  }
}
