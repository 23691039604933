import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { FileUploadModel } from "@/domain/file-upload/model/FileUploadModel";
import { FileUploadRequestDto } from "@/domain/file-upload/dto/FileUploadRequestDto";
import { FileUploadResponseDto } from "@/domain/file-upload/dto/FileUploadResponseDto";

export class FileUploadMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: FileUploadResponseDto): Result<FileUploadModel> {
    try {
      const fileUploadModel: FileUploadModel = {
        data: null,
        id: dto.data.id,
        name: dto.data.attributes.name,
        alternativeText: dto.data.attributes.alternativeText,
        caption: dto.data.attributes.caption,
        formats: dto.data.attributes.formats,
        hash: dto.data.attributes.hash,
        ext: dto.data.attributes.ext,
        mime: dto.data.attributes.mime,
        size: dto.data.attributes.size,
        url: dto.data.attributes.url,
        previewUrl: dto.data.attributes.previewUrl,
        provider: dto.data.attributes.provider,
        folderPath: dto.data.attributes.folderPath,
      };
      return Result.ok(fileUploadModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.FILE_UPLOAD))
      );
    }
  }

  mapToDTO(domain: FileUploadModel): Result<FileUploadRequestDto> {
    try {
      const fileUploadRequest = new FileUploadRequestDto(domain.data);

      return Result.ok(fileUploadRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.FILE_UPLOAD))
      );
    }
  }
}
