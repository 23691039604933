import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { StaffListUseCase } from "@/application/staff/staff-list/usecase/StaffListUseCase";
import { StaffListModel } from "@/domain/staff/staff-list/model/StaffListModel";
import { DeleteStaffModel } from "@/domain/staff/delete-staff/model/DeleteStaffModel";
import { DeleteStaffUseCase } from "@/application/staff/delete-staff/usecase/DeleteStaffUseCase";
import { InviteStaffModel } from "@/domain/staff/invite-staff/model/InviteStaffModel";
import { InviteStaffUseCase } from "@/application/staff/invite-staff/usecase/InviteStaffUseCase";
import { AcceptInviteUseCase } from "@/application/staff/invite-staff/accept/usecase/AcceptInviteUseCase";
import { AcceptInviteModel } from "@/domain/staff/invite-staff/accept/model/AcceptInviteModel";

export function createStaffController() {
  const staffListUseCase = new StaffListUseCase();
  const deleteStaffUseCase = new DeleteStaffUseCase();
  const inviteStaffUseCase = new InviteStaffUseCase();
  const acceptInviteUseCase = new AcceptInviteUseCase();

  return new StaffController(
    staffListUseCase,
    inviteStaffUseCase,
    deleteStaffUseCase,
    acceptInviteUseCase
  );
}

export class StaffController implements IController {
  constructor(
    private staffListUseCase: StaffListUseCase,
    private inviteStaffUseCase: InviteStaffUseCase,
    private deleteStaffUseCase: DeleteStaffUseCase,
    private acceptInviteUseCase: AcceptInviteUseCase
  ) {}

  async staffList(
    staffListModel: StaffListModel
  ): Promise<Result<StaffListModel>> {
    const response = await this.staffListUseCase.execute(staffListModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<StaffListModel>(response.getError());
    }
  }

  async deleteStaff(
    deleteStaffModel: DeleteStaffModel
  ): Promise<Result<DeleteStaffModel>> {
    const response = await this.deleteStaffUseCase.execute(deleteStaffModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<DeleteStaffModel>(response.getError());
    }
  }

  async inviteStaff(
    inviteStaffModel: InviteStaffModel
  ): Promise<Result<InviteStaffModel>> {
    const response = await this.inviteStaffUseCase.execute(inviteStaffModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<InviteStaffModel>(response.getError());
    }
  }

  async acceptInvite(
    acceptInviteModel: AcceptInviteModel
  ): Promise<Result<AcceptInviteModel>> {
    const response = await this.acceptInviteUseCase.execute(acceptInviteModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<AcceptInviteModel>(response.getError());
    }
  }
}
