
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IDeleteJobPostingUseCase } from "@/domain/interview/delete-job-posting/usecase/IDeleteJobPostingUseCase";
import { DeleteJobPostingRepository } from "../repository/DeleteJobPostingRepository";
import { IDeleteJobPostingRepository } from "@/domain/interview/delete-job-posting/repository/IDeleteJobPostingRepository";
import { DeleteJobPostingMapper } from "../mapper/DeleteJobPostingMapper";
import { DeleteJobPostingModel } from "@/domain/interview/delete-job-posting/model/DeleteJobPostingModel";

export class DeleteJobPostingUseCase implements IDeleteJobPostingUseCase {
  deleteJobPostingRepository : IDeleteJobPostingRepository = new DeleteJobPostingRepository();
  deleteJobPostingMapper = new DeleteJobPostingMapper();

  constructor() {
  }

  async execute(model: DeleteJobPostingModel): Promise<Result<DeleteJobPostingModel>> {
    try {
      const dto = this.deleteJobPostingMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<DeleteJobPostingModel>(dto.getError());
      } else {
        const response = await this.deleteJobPostingRepository.deleteJobPosting(dto);

        if (response.isSuccess) {
          const domain = this.deleteJobPostingMapper.mapToDomain(
            response.getValue()
          );

          return Result.ok<DeleteJobPostingModel>(domain.getValue());
        } else {
          return Result.fail<DeleteJobPostingModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<DeleteJobPostingModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.DELETE_INTERVIEW))
      );
    }
  }
}
