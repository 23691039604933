
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IPollResponseListUseCase } from "@/domain/pollster/poll-statistic/poll-response-list/usecase/IPollResponseListUseCase";
import { PollResponseListRepository } from "../repository/PollResponseListRepository";
import { IPollResponseListRepository } from "@/domain/pollster/poll-statistic/poll-response-list/repository/IPollResponseListRepository";
import { PollResponseListMapper } from "../mapper/PollResponseListMapper";
import { PollResponseListModel } from "@/domain/pollster/poll-statistic/poll-response-list/model/PollResponseListModel";

export class PollResponseListUseCase implements IPollResponseListUseCase {
  getPollResponseListRepository : IPollResponseListRepository = new PollResponseListRepository();
  getPollResponseListMapper = new PollResponseListMapper ();

  constructor() {
  }

  async execute(model: PollResponseListModel): Promise<Result<PollResponseListModel>> {
    try {
      const dto = this.getPollResponseListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<PollResponseListModel>(dto.getError());
      } else {
        const response = await this.getPollResponseListRepository.getPollResponseList(dto);

        if (response.isSuccess) {
          const domain = this.getPollResponseListMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<PollResponseListModel>(domain.getValue());
        } else {
          return Result.fail<PollResponseListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<PollResponseListModel>(
        new UseCaseException(new UnknownCause(USECASE.POLLSTER.STATISTIC.POLL_RESPONSE_LIST))
      );
    }
  }
}
