import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IGetPurchasedProductsService } from "@/domain/product/get-purchased-products/service/IGetPurchasedProductsService";
import { GetPurchasedProductsRequestDto } from "@/domain/product/get-purchased-products/dto/GetPurchasedProductsRequestDto";
import { GetPurchasedProductsResponseDto } from "@/domain/product/get-purchased-products/dto/GetPurchasedProductsResponseDto";

export default class GetPurchasedProductsService implements IGetPurchasedProductsService {
  async getList(
    request: GetPurchasedProductsRequestDto
  ): Promise<Result<GetPurchasedProductsResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.PURCHASED_PACKAGE + request.toQueryString())
        .then((response) => {
          return Result.ok<GetPurchasedProductsResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<GetPurchasedProductsResponseDto>(
            new BadRequestException(
              new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_PRODUCT_LIST))
            )
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(new UnknownCause(SERVICE.PRODUCT.GET_PURCHASED_PRODUCT))
      );
    }
  }
}
