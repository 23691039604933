import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { PollListUseCase } from "@/application/pollster/poll-list/usecase/PollListUseCase";
import { PollListModel } from "@/domain/pollster/poll-list/model/PollListModel";
import { CreatePollUsecase } from "@/application/pollster/create-poll/usecase/CreatePollUseCase";
import { CreatePollModel } from "@/domain/pollster/create-poll/model/CreatePollModel";
import { DeletePollModel } from "@/domain/pollster/delete-poll/model/DeletePollModel";
import { DeletePollUseCase } from "@/application/pollster/delete-poll/usecase/DeletePollUseCase";
import { UpdatePollModel } from "@/domain/pollster/update-poll/model/UpdatePollModel";
import { UpdatePollUseCase } from "@/application/pollster/update-poll/usecase/UpdatePollUseCase";
import { PollDetailModel } from "@/domain/pollster/poll-detail/model/PollDetailModel";
import { PollDetailUseCase } from "@/application/pollster/poll-detail/usecase/PollDetailUseCase";
import { InviteUserModel } from "@/domain/pollster/Invite-user/model/InviteUserModel";
import { InviteUserUsecase } from "@/application/pollster/invite-user/usecase/InviteUserUseCase";
import { FileUploadUsecase } from "@/application/file-upload/usecase/FileUploadUseCase";
import { FileUploadModel } from "@/domain/file-upload/model/FileUploadModel";
import { StatisticPollListUseCase } from "@/application/pollster/poll-statistic/poll-list/usecase/PollListUseCase";
import { StatisticPollListModel } from "@/domain/pollster/poll-statistic/poll-list/model/PollListModel";
import { PollSessionDetailUseCase } from "@/application/pollster/poll-statistic/poll-session-detail/usecase/PollSessionDetailUseCase";
import { PollSessionDetailModel } from "@/domain/pollster/poll-statistic/poll-session-detail/model/PollSessionDetailModel";
import { PollMessageListUseCase } from "@/application/pollster/poll-statistic/poll-message-list/usecase/PollMessageListUseCase";
import { PollMessageListModel } from "@/domain/pollster/poll-statistic/poll-message-list/model/PollMessageListModel";
import { PollResponseListUseCase } from "@/application/pollster/poll-statistic/poll-response-list/usecase/PollResponseListUseCase";
import { PollResponseListModel } from "@/domain/pollster/poll-statistic/poll-response-list/model/PollResponseListModel";

export function createPollController() {
  const pollsterListUseCase = new PollListUseCase();
  const createPollUseCase = new CreatePollUsecase();
  const deletePollUseCase = new DeletePollUseCase();
  const updatePollUseCase = new UpdatePollUseCase();
  const pollDetailUseCase = new PollDetailUseCase();
  const inviteUserUseCase = new InviteUserUsecase();
  const fileUploadUseCase = new FileUploadUsecase();
  const statisticPollListUseCase = new StatisticPollListUseCase();
  const pollSessionDetailUseCase = new PollSessionDetailUseCase();
  const pollMessageListUseCase = new PollMessageListUseCase();
  const pollResponseListUseCase = new PollResponseListUseCase();

  return new PollController(
    pollsterListUseCase,
    createPollUseCase,
    deletePollUseCase,
    updatePollUseCase,
    pollDetailUseCase,
    inviteUserUseCase,
    fileUploadUseCase,
    statisticPollListUseCase,
    pollSessionDetailUseCase,
    pollMessageListUseCase,
    pollResponseListUseCase
  );
}

export class PollController implements IController {
  constructor(
    private pollsterListUseCase: PollListUseCase,
    private createPollUseCase: CreatePollUsecase,
    private deletePollUseCase: DeletePollUseCase,
    private updatePollUseCase: UpdatePollUseCase,
    private pollDetailUseCase: PollDetailUseCase,
    private inviteUserUseCase: InviteUserUsecase,
    private fileUploadUseCase: FileUploadUsecase,
    private statisticPollListUseCase: StatisticPollListUseCase,
    private pollSessionDetailUseCase: PollSessionDetailUseCase,
    private pollMessageListUseCase: PollMessageListUseCase,
    private pollResponseListUseCase: PollResponseListUseCase
  ) {}

  async pollList(pollListModel: PollListModel): Promise<Result<PollListModel>> {
    const response = await this.pollsterListUseCase.execute(pollListModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<PollListModel>(response.getError());
    }
  }

  async createPoll(
    createPollModel: CreatePollModel
  ): Promise<Result<CreatePollModel>> {
    const response = await this.createPollUseCase.execute(createPollModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<CreatePollModel>(response.getError());
    }
  }

  async deletePoll(
    deletePollModel: DeletePollModel
  ): Promise<Result<DeletePollModel>> {
    const response = await this.deletePollUseCase.execute(deletePollModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<DeletePollModel>(response.getError());
    }
  }

  async updatePoll(
    updatePollModel: UpdatePollModel
  ): Promise<Result<UpdatePollModel>> {
    const response = await this.updatePollUseCase.execute(updatePollModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<UpdatePollModel>(response.getError());
    }
  }

  async getPollDetail(
    pollDetailModel: PollDetailModel
  ): Promise<Result<PollDetailModel>> {
    const response = await this.pollDetailUseCase.execute(pollDetailModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<PollDetailModel>(response.getError());
    }
  }

  async inviteUser(
    inviteUserModel: InviteUserModel
  ): Promise<Result<InviteUserModel>> {
    const response = await this.inviteUserUseCase.execute(inviteUserModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<InviteUserModel>(response.getError());
    }
  }

  async statisticPollList(
    statisticPollListModel: StatisticPollListModel
  ): Promise<Result<StatisticPollListModel>> {
    const response = await this.statisticPollListUseCase.execute(
      statisticPollListModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<StatisticPollListModel>(response.getError());
    }
  }

  async pollSessionDetail(
    pollSessionDetailModel: PollSessionDetailModel
  ): Promise<Result<PollSessionDetailModel>> {
    const response = await this.pollSessionDetailUseCase.execute(
    pollSessionDetailModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<PollSessionDetailModel>(response.getError());
    }
  }

  async pollSessionMessages(
    pollMessageListModel: PollMessageListModel
  ): Promise<Result<PollMessageListModel>> {
    const response = await this.pollMessageListUseCase.execute(
      pollMessageListModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<PollMessageListModel>(response.getError());
    }
  }

  async pollResponseList(
    pollResponseListModel: PollResponseListModel
  ): Promise<Result<PollResponseListModel>> {
    const response = await this.pollResponseListUseCase.execute(
      pollResponseListModel
    );

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<PollResponseListModel>(response.getError());
    }
  }
}
