import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import PollResponseListService from "../service/PollResponseListService";
import { IPollResponseListRepository } from "@/domain/pollster/poll-statistic/poll-response-list/repository/IPollResponseListRepository";
import { PollResponseListRequestDto } from "@/domain/pollster/poll-statistic/poll-response-list/dto/PollResponseListRequestDto";
import { PollResponseListResponseDto } from "@/domain/pollster/poll-statistic/poll-response-list/dto/PollResponseListResponseDto";

const getPollResponseList = new PollResponseListService();

export class PollResponseListRepository implements IPollResponseListRepository {
  async getPollResponseList(request: PollResponseListRequestDto): Promise<Result<PollResponseListResponseDto>> {
    try {
      return await getPollResponseList
        .get(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<PollResponseListResponseDto>(response.getValue());
          } else {
            return Result.fail<PollResponseListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>(new BadRequestException(error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.POLLSTER.STATISTIC.POLL_RESPONSE_LIST)));
    }
  }
}
