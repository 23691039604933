import { BaseDTO } from "@/core/base/BaseDTO";

export class FileUploadRequestDto extends BaseDTO {
  formData : FormData

  constructor(data: File) {
    super();
    this.formData = new FormData();
    // this.file = data
    this.formData.append("file", data)
  }
}
