export default {
  project: "İş Asistan",
  year: "2024",
  version: "1.1.14",
  common: {
    pleaseWait: "Lütfen bekleyiniz...",
    process: "İşlem",
    loading: "Yükleniyor...",
    layout: {
      contact: "Bize Ulaşın",
      profile: "Profilim",
      language: "Dil",
      signOut: "Çıkış Yap",
    },
    button: {
      create: "Oluştur",
      add: "Ekle",
      start: "Başla",
      save: "Kaydet",
      cancel: "İptal",
      edit: "Düzenle",
      verify: "Doğrula",
      ok: "Tamam",
      continue: "Devam Et",
      send: "Gönder",
      install: "Yükle",
      delete: "Sil",
      yes: "Evet",
      no: "Hayır",
      invite: "Davet Et",
    },
    table: {
      process: "İşlem",
      notProcess: "Varsayılan olduğu için işlem yapılamaz",
      pagination: {
        desc: " sonuç arasından ",
        desc1: "sonuç gösteriliyor.",
        desc2: "sayfa başı öğe sayısı",
      },
    },
    times: {
      minuteAgo: "dakika önce",
      hourAgo: "saat önce",
      dayAgo: "gün önce",
      monthAgo: "ay önce",
    },
    dontLiked: "Beğenmedim",
    continues: "Devam ediyor",
    completed: "Tamamlandı",
    ended: "Sonlandı",
    general: "Genel",
    special: "Özel",
  },
  asideMenu: {
    modules: {
      header: "Modüller",
      activeModules: "Aktif Modüller",
      notFoundActiveModule: "Aktif modül bulunamadı.",
      hotelManagement: {
        title: "AI Otel Asistanı",
        assistant: "AI Asistan",
        history: "Konuşma Arşivleri",
        staffList: {
          title: "Personeller",
          desc: "Şirketinize kayıtlı olan personelleri bu sayfada görüntüleyebilir, geçmişteki AI asistan konuşma kayıtlarını inceleyebilirsiniz.",
        },
      },
      pollster: {
        title: "Anketör",
        polls: "Anket Listesi",
        userAssignment: "Anket Ataması Yap",
        userPolls: "Kullanıcı Anketleri",
        myPolls: "Anketlerim",
        create: "Anket Oluştur",
        emailAddresses: "E-posta Adresleri",
      },
      interview: {
        title: "Mülakatör",
        create: "Mülakat Oluştur",
        historyList: "Geçmiş Mülakatlar",
        list: "Mülakat Listesi",
        userAssignment: "Mülakat Daveti Gönder",
      },
    },
    admin: {
      header: "Admin",
      modules: "Tüm Modüller",
      accountTypes: "Hesap Türleri",
      accountStatus: "Hesap Durumu",
      staff: "Personeller",
      inviteStaff: "Personel Davet Et",
    },
    ogza: {
      header: "Ogzatech Yönetim",
      modules: {
        title: "Modüller",
        allModules: "Tüm Modüller",
        createModul: "Modül Oluştur",
      },
      companies: "Kayıtlı Şirketler",
      createCompany: "Şirket Oluştur",
      chatgptSettings: "ChatGpt Ayarları",
    },
    user: "Kullanıcı",
  },
  landingPage: {
    menu: {
      home: "Anasayfa",
      assistant: "İş Asistanı",
      login: "Giriş Yap",
    },
    headerBanner: {
      title: "Yapay Zeka ile İş Sürecinizi Kolaylaştırın!",
      desc: "Yapay zeka ile iş süreçlerinizi bir üst seviyeye taşıyın! Verimliliği artıran, zaman kazandıran ve maliyetleri düşüren akıllı otomasyon çözümlerimizle tanışın. İş yükünüzü hafifletin, önemli işlere odaklanın ve başarınızı katlayın.",
    },
    container: {
      mainTitle: "AI İş Asistanı",
      mainHeader: "Yapay Zeka Destekli İş Asistanı",
      mainDesc:
        " Yapay zeka desteğiyle oluşturduğumuz asistan modülleri ile işinizi büyük oranda kolaylaştırın. Personel ve müşterilerinize kısa yoldan daha hızlı ve verimli hizmet sunun. İş süreçlerinizi optimize eden bu modüller, tekrarlayan görevleri otomatikleştirir, müşteri taleplerini anında yanıtlar ve veri analizleri ile stratejik kararlar almanıza yardımcı olur.",
      title: "Yapay Zekalı Otel Asistanı",
      desc: "Otelinize yapılan müşteri yorumlarını yapay zeka desteğiyle gerçeğe yakın şekilde duygu analizi ile birlikte değerlendirilmesini sağlar.",
      title2: "Yapay Zeka Destekli Anketör",
      desc2:
        "Şirket içi ve şirket dışı anketlerinizi yapay zeka yardımıyla yaparak kısa sürede ve verimli şekilde gerçekleştirin. Duygu analiziyle cevapların doğruluğunu saptayın ve kaydedin.",
      title3: "Yapay Zeka ile Canlı Mülakat",
      desc3:
        "İşe alım süreçlerinizi yapay zeka ile daha verimli hale getirin. Adaylarla canlı mülakat yaparak performanslarını analiz edin ve en uygun adayları belirleyin. Böylece daha hızlı ve doğru işe alım kararları alın.",
    },
  },
  login: {
    title: "Giriş Yap",
    desc: "Lütfen giriş yapabilmek için telefon numaranızı giriniz.",
    desc2: "Lütfen giriş yapabilmek için hesap bilgilerinizi giriniz.",
    email: "E-posta",
    enterEmail: "Lütfen e-posta adresinizi giriniz...",
    password: "Şifre",
    enterPassword: "Lütfen şifrenizi giriniz...",
    phone: "Telefon Numarası",
    country: "Ülke",
    forgotPassword: "Şifremi Unuttum",
    button: "Giriş",
  },
  verifyOTP: {
    title: "SMS Doğrulama",
    desc: "Lütfen telefonunuza gelen kısa mesajda yer alan doğrulama kodunu giriniz.",
    code: "Doğrulama Kodu",
    enterCode: "Doğrulama kodunu giriniz...",
  },
  profileSet: {
    title: "Hesap Bilgileri",
    title2: "Profil Oluştur",
    desc: "Lütfen hesap bilgilerinizi güncelleyiniz...",
    desc2: "Lütfen hesabınızı oluşturunuz...",
    name: "Ad",
    enterName: "Adınızı giriniz...",
    surname: "Soyad",
    enterSurname: "Soyadınızı giriniz...",
    email: "E-posta",
    enterEmail: "E posta adresinizi giriniz...",
    company: "Şirket",
    enterCompany: "Şirket adı giriniz...",
    button: "Kaydet ve Giriş Yap",
  },
  acceptInvite: {
    title: "Daveti Kabul Et",
    desc: "Daveti kabul etmek için aşağıdaki butona tıklayınız.",
    button: "Kabul Et",
  },
  companies: {
    createCompany: "Yeni Şirket Oluştur",
    createCompanyDesc:
      "Kendi şirketinizde yapay zeka modüllerini kullanın ve iş akışınızı kolaylaştırın.",
  },
  createCompany: {
    title: "Şirket Oluştur",
    desc: "Lütfen şirketinizin bilgilerini giriniz...",
    companyName: "Şirket Adı",
    enterCompanyName: "Şirket adı giriniz...",
    companyDesc: "Şirket Açıklaması",
    enterCompanyDesc: "Şirketinizi tanımlayınız...",
    sector: "Sektör",
    enterSector: "Sektör giriniz...",
  },
  dashboard: {
    title: "Anasayfa",
    chart: {
      title: "Toplam İşlem Sayısı",
      desc: "Son 7 günlük toplam işlem grafiği",
    },
    sections: {
      totalProcessCount: "Toplam İşlem Sayısı",
      modalCount: "Modül Sayısı",
      staffCount: "Personel Sayısı",
      todaysprocessCount: "Bugünkü İşlem Sayısı",
    },
    lastProcess: {
      title: "Son İşlemler",
      type: "İŞLEM TÜRÜ",
      nameSurname: "AD SOYAD",
      email: "E-POSTA",
      time: "TAMAMLANMA ZAMANI",
    },
  },
  profile: {
    title: "Profil",
    personelInfo: "Kişisel Bilgiler",
    name: "Ad",
    enterName: "Adınızı giriniz",
    surname: "Soyad",
    enterSurname: "Soyadınızı giriniz",
    company: "Şirket",
    enterCompany: "Şirket adı giriniz",
    phone: "Telefon",
    enterPhone: "Telefon numarası giriniz...",
    email: "E-posta",
    enterEmail: "E posta adresinizi giriniz...",
  },
  modals: {
    detail: {
      pageTitle: "Modül Detayları",
      current: "Güncel",
      modalDescription: "Modül Açıklaması",
      packages: "Paketler",
      numberOfUses: "Kullanım Hakkı",
      dayCount: "Gün Sayısı",
      purchased: "Satın Alındı",
      notPurchased: "Satın Alınabilir",
      free: "Ücretsiz",
      FAQS: "Sıkça Sorulan Sorular",
      settings: "Modül Ayarları",
      activeAiModel: "Aktif Yapay Zeka Modeli",
      details: {
        title: "Detaylar",
        update: "Güncelleme",
        techSupport: "Teknik Destek",
        licence: "Lisans",
        installation: "Kurulum",
        lifeLong: "Ömür Boyu",
        full: "Full",
        oneClick: "Tek Tıklama",
      },
    },
    hotel: {
      title: "AI Otel Asistanı",
      search: "Arama...",
      startNewSession: "Yeni Konuşma Başlat",
      newSession: "Yeni Konuşma",
      sessionCount: "Kalan Oturum Sayısı",
      zeroSession: "Konuşma geçmişi bulunamadı.",
      enterMessage: "Mesajınız...",
      emptyMessage: "Lütfen boş mesaj göndermeyiniz.",
      staffChatHistory: "Şu anda personel sohbet geçmişini görüntülüyorsunuz.",
      staffHistories: "Personel Konuşma Geçmişleri",
      staffHistoriesDesc:
        "Lütfen incelemek istediğiniz konuşma geçmişini seçiniz...",
      newChatDesc:
        "Otel asistanına hoşgeldiniz! Oteliniz ile alakalı danışmak istediğiniz konuyu veya müşteri yorumunu iletmek için lütfen ileti gönderiniz. Yapay zeka, size sıra dışı yetenekleriyle gerçeğe yakın cevaplar sunacak ve müşterilerinizin memnuniyeti sağlayacaktır.",
      closedSession:
        "Bu sohbet tamamlanmıştır. Yeni sohbet başlamak için lütfen sol menüde yer alan 'Yeni Konuşma Başlat' butonuna tıklayınız.",
      staffList: {
        pageTitle: "Personeller",
        title: "Personel Listesi",
        desc: "Şirketinize kayıtlı olan personelleri bu sayfada görüntüleyebilir, geçmiş ai asistan modül sohbet kayıtlarını inceleyebilirsiniz.",
        inviteStaff: {
          title: "Personel Davet Et",
          createStaff: "Personel Davet Et",
          name: "Personel Adı",
          enterName: "Adı giriniz...",
          surname: "Personel Soyadı",
          enterSurname: "Soyadı giriniz...",
          email: "Personel E-posta",
          enterEmail: "E-posta adresi giriniz...",
          phone: "Personel Telefonu",
          enterPhone: "Telefon numarası giriniz...",
          birthDate: "Doğum Tarihi",
          enterBirthDate: "Doğum tarihini giriniz...",
          department: "Departman",
          enterDepartment: "Departmanı yazınız...",
          position: "Pozisyon",
          enterPosition: "Pozisyonu yazınız...",
        },
        name: "Ad",
        surname: "Soyad",
        phone: "Telefon Numarası",
        email: "E-posta",
        department: "Departman",
        mission: "Görevi",
        sessionCount: "Konuşma Sayısı",
        zeroCount: "Personel arşivi bulunamadı.",
      },
    },
    pollster: {
      title: "Anketör",
      poll: "Anket",
      createPollster: "Anket Oluştur",
      pollDetails: "Anket Detayları",
      userAssignment: "Kullanıcı Anket Ataması",
      createLink: "Link Oluştur",
      staffPoll: "Personel Anketi",
      singlePollType: "Tekil Anket Türü",
      singlePollTypeDesc:
        "Tekil olan anketler, her kullanıcı için bireysel şekilde tek kullanımlık olarak gönderilmek üzere oluşturulur.",
      addQuestion: "Soru Ekle",
      openEnded: "Açık Uçlu",
      singleChoice: "Tek Seçenekli",
      multipleChoice: "Çok Seçenekli",
      responseOptions: "Cevap Seçenekleri",
      updateEndDate: "Bitiş Tarihi Güncelle",
      updateEndDateDesc:
        " Anketin bitiş tarihini güncellemek için lütfen yeni tarih giriniz.",
      addOption: "Seçenek Ekle",
      startPoll: "Anketi Başlat",
      emailPoll: "Kayıtlı E-posta Anketi",
      name: "Ad",
      enterName: "Adı giriniz...",
      surname: "Soyad",
      enterSurname: "Soyadı giriniz...",
      email: "E-posta",
      enterEmail: "E-posta adresi giriniz...",
      enterEmailDesc:
        "                         NOT: Birden fazla E-posta adresi girmek için lütfen virgül ile ayırınız.",
      selectPoll: "Anket seçiniz...",
      pollDesc: "Anket Açıklaması",
      link: "Anket Linki",
      type: "Anket Türü",
      staff: "Personel",
      sessionCode: "Oturum Kodu",
      enterSessionCode: "Oturum Kodu giriniz...",
      pollsHistorys: "Geçmiş Anketler",
      polls: "Anketler",
      pollsDesc:
        "Anketör uygulamasında önceden tanımladığınız anketleri bu sayfada görüntüleyebilirsiniz.",
      userPolls: {
        title: "Kullanıcı Anketleri",
        desc: "Anketör uygulamasında kullanıcıların yaptığı anketleri bu sayfada görüntüleyebilirsiniz.",
        poll: "Anket",
        status: "Durum",
        duration: "Süre",
        pollType: "Anket Türü",
        endDate: "Bitiş Tarihi",
        detail: "Detay",
      },
      userPollDetail: {
        header: "Kullanıcı Anket Detayları",
        tabs: {
          one: "Anket Bilgileri",
          two: "Kullanıcı Yanıtları",
          three: "Konuşma Logları",
        },
      },
      isStaff: "PERSONEL MI?",
      nameSurname: "AD SOYAD",
      date: "Tamamlanma Zamanı",
      pollTitle: "Anket Başlığı",
      enterTitle: "Başlık giriniz...",
      desc: "Açıklama",
      enterDesc: "Açıklama giriniz...",
      startDate: "Başlangıç Tarihi",
      enterStartDate: "Tarih seçiniz...",
      endDate: "Bitiş Tarihi",
      enterEndDate: "Tarih seçiniz...",
      duration: "Süre",
      percentComplete: "Tamamlanma Yüzdesi",
      status: "Durum",
      questionCount: "Soru Sayısı",
      sessionCount: "Oturum Sayısı",
      question: "Soru",
      userAnswer: "Kullanıcı Cevabı",
      enterQuestion: "Soruyu yazınız...",
      questionType: "Soru Tipi",
      enterQuestionType: "Soru tipi seçiniz...",
      emails: {
        title: "E-posta Adresleri",
        desc: "Anketör uygulamasına kayıtlı e-posta adreslerini bu sayfada yönetebilirsiniz.",
        createNewEmail: "Yeni E-posta Ekle",
        createEmailTitle: "E-posta Ekle",
        createEmailDesc: "Modüle eklemek istediğiniz e-posta adresini giriniz",
        email: "E-posta",
        enterEmail: "E-posta adresini giriniz...",
        pollCount: "Anket Sayısı",
      },
      firstAiMessage:
        "Merhaba ben Pelin, lütfen size soracak olduğum sorulara doğrulukla cevap verin, yapacağınız tüm geri dönüşler ürünlerimizi iyileştirmeye yönelik kayıt altına alınıp değerlendirilecektir.",
      pushToTalk: "Bas konuş...",
      aiPollster: "Yapay Zeka Anketörü",
      active: "Aktif",
      deActive: "Pasif",
      endConversation: "Görüşmeyi Sonlandır",
      time: "Süre",
      zeroCount: "Anket bulunamadı. Lütfen yeni bir anket ekleyiniz. ",
    },
    interview: {
      title: "Mülakatör",
      interviewList: "Mülakat Listesi",
      desc: "Mülakatör uygulamasında önceden tanımladığınız iş tanımlarına ait mülakatları bu sayfada görüntüleyebilirsiniz.",
      interviewDesc: "Açıklama",
      createInterview: "Mülakat Oluştur",
      name: "Ad",
      enterName: "Adı giriniz...",
      surname: "Soyad",
      enterSurname: "Soyadı giriniz...",
      email: "E-posta",
      enterEmail: "E-posta adresi giriniz...",
      link: "Mülakat Linki",
      interviewsHistorys: "Geçmiş Mülakatlar",
      nameSurname: "AD SOYAD",
      date: "Tamamlanma Zamanı",
      status: "Durum",
      questionCount: "Soru Sayısı",
      sessionCount: "Oturum Sayısı",
      jobPosting: "İş İlanı",
      jobPostingTitle: "İlan Başlığı",
      enterJobPostingTitle: "Başlık giriniz...",
      interviewTitle: "Mülakat Başlığı",
      enterInterviewTitle: "Mülakat başlığı giriniz...",
      position: "Pozisyon",
      enterPosition: "Pozisyonu giriniz...",
      description: "Açıklama",
      enterDescription: "Açıklama giriniz...",
      interviewDetail: "Mülakat Detayları",
      startDate: "Başlangıç Tarihi",
      enterStartDate: "Tarih seçiniz...",
      endDate: "Bitiş Tarihi",
      enterEndDate: "Tarih seçiniz...",
      addQuestion: "Soru Ekle",
      question: "Soru",
      questionType: "Soru Tipi",
      enterQuestion: "Soruyu yazınız...",
      enterQuestionType: "Soru tipi seçiniz...",
      openEnded: "Açık Uçlu",
      singleChoice: "Tek Seçenekli",
      multipleChoice: "Çok Seçenekli",
      responseOptions: "Cevap Seçenekleri",
      addOption: "Seçenek Ekle",
      updateEndDate: "Bitiş Tarihi Güncelle",
      updateEndDateDesc: " Mülakatın bitiş tarihini güncellemek için lütfen yeni tarih giriniz.",
      startInterview: {
        title: "Mülakata Başla",
        desc: "Lütfen mülakata başlamak için kişisel bilgilerinizi giriniz...",
        name: "Ad",
        enterName: "Adınızı giriniz...",
        surname: "Soyad",
        enterSurname: "Soyadınızı giriniz...",
        email: "E-posta",
        enterEmail: "E-posta giriniz...",
      },
      addCandidate: {
        title: "Mülakat Daveti Oluştur",
        interview: "Mülakat",
        selectInterview: "Mülakat seçiniz...",
        name: "Ad",
        enterName: "Adı giriniz...",
        surname: "Soyad",
        enterSurname: "Soyadı giriniz...",
        email: "E-posta",
        enterEmail: "E-posta giriniz...",
      },
      userInterviewDetail: {
        header: "Kullanıcı Mülakat Detayları",
        tabs: {
          one: "Mülakat Bilgileri",
          two: "Kullanıcı Yanıtları",
          three: "Konuşma Logları",
        },
      },
      firstAiMessage:
        "Merhaba, ben yapay zeka mülakatörü. Sizi tanımak için buradayım. Lütfen kendinizi rahat hissedin ve doğal bir şekilde sorularımı yanıtlayın.",
      pushToTalk: "Basılı tut ve konuş...",
      percentComplete: "Görüşme Tamamlanma Yüzdesi: ",
      aiInterviewer: "Yapay Zeka Mülakatatörü",
      active: "Aktif",
      deActive: "Pasif",
      endConversation: "Görüşmeyi Sonlandır",
      time: "Süre",
      zeroCount: "Mülakat bulunamadı. Lütfen yeni bir mülakat ekleyiniz. ",
    },
  },
  modalList: {
    title: "Modül Listesi",
    allModules: "Tüm",
    myPurchases: "Satın Aldıklarım",
    freeOnes: "Ücretsiz Olanlar",
    availableForPurchase: "Satın Alınabilir",
    notFound: "Modül bulunamadı.",
  },
  ogza: {
    staffList: {
      pageTitle: "Personeller",
      title: "Personel Listesi",
      desc: "Şirketinize kayıtlı olan personelleri bu sayfada görüntüleyebilir, geçmiş kayıtlarını inceleyebilirsiniz.",
      inviteStaff: {
        title: "Personel Davet Et",
        createStaff: "Personel Davet Et",
        name: "Personel Adı",
        enterName: "Adı giriniz...",
        surname: "Personel Soyadı",
        enterSurname: "Soyadı giriniz...",
        email: "Personel E-posta",
        enterEmail: "E-posta adresi giriniz...",
        phone: "Personel Telefonu",
        enterPhone: "Telefon numarası giriniz...",
        birthDate: "Doğum Tarihi",
        enterBirthDate: "Doğum tarihini giriniz...",
        department: "Departman",
        enterDepartment: "Departmanı yazınız...",
        position: "Pozisyon",
        enterPosition: "Pozisyonu yazınız...",
      },
      name: "Ad",
      surname: "Soyad",
      phone: "Telefon Numarası",
      email: "E-posta",
      department: "Departman",
      mission: "Görevi",
      status: "Durum",
      zeroCount: "Personel kaydı bulunamadı.",
    },
    chatGptSettings: {
      title: "ChatGpt Ayarları",
      activeVersion: "Aktif ChatGpt Sürümü",
      selectVersion: "Versiyon seçiniz...",
      apiKey: "Api Key",
      enterApiKey: "Api Key giriniz...",
    },
    createModal: {
      title: "Modül Oluştur",
      modalIcon: "Modül İkonu",
      modalIconDesc: "İzin verilen dosya türleri: png, jpg, jpeg..",
      modalName: "Modül Adı",
      enterModalName: "Modül Adı giriniz...",
      modalDesc: "Modül Tanımı",
      enterModalDesc: "Modül Tanımı giriniz...",
      price: "Fiyat",
      enterPrice: "Fiyatı giriniz...",
      detailedDesc: "Detaylı Açıklama",
      enterDetailedDesc: "Açıklama yazınız...",
      question1: "Soru 1",
      question2: "Soru 2",
      enterQuestion: "Soru giriniz...",
      answer: "Cevap",
      enterAnswer: "Cevap giriniz...",
      adminPropmt: "Modül Admin Promptu",
      enterPrompt: "Promptu giriniz...",
      userSessionLimit: "Kullanıcı Session Sınırı",
      enterLimit: "Miktar giriniz...",
      details: "Detaylar",
      speakOutLoud: "Sesli konuşma",
      videoCall: "Görüntülü görüşme",
      guestUser: "Dışarıdan misafir kullanıcılar erişebilir mi?",
    },
    createCompany: {
      title: "Şirket Oluştur",
      companyName: "Şirket Adı",
      enterCompanyName: "Şirket Adı giriniz...",
      companyCategory: "İş Kategorisi",
      enterCompanyCategory: "Kategori giriniz...",
      managerName: "Yetkili Adı",
      enterManagerName: "Yetkili Adı giriniz...",
      managerSurname: "Yetkili Soyadı",
      enterManagerSurname: "Yetkili Soyadı giriniz...",
      email: "E-posta Adresi",
      enterEmail: "E-posta adresini giriniz...",
      country: "Ülke",
      phone: "Telefon Numarası",
      enterPhone: "Telefon numarası giriniz...",
      department: "Departman",
      enterDepartment: "Departmanı giriniz...",
      accountType: "Hesap Türü",
      selectAccountType: "Hesap Türü seçiniz...",
    },
  },
  accountStatus: {
    pageTitle: "Hesap Durumu",
    title: "Hesap Bilgileri",
    companyName: "Şirket Adı",
    enterCompanyName: "Şirket Adı giriniz...",
    email: "E-posta",
    enterEmail: "E-posta adresi giriniz...",
    accountType: "Hesap Türü",
    activeModalCount: "Aktif Modül Sayısı",
    processCountofPeriod: "Dönem içi İşlem Sayısı",
    purchaseHistory: "Satın Alma Geçmişi",
    receivedModule: "Alınan Modül",
    price: "Fiyat",
    procesDate: "İşlem Tarihi",
  },
  accountTypes: {
    title: "Hesap Türleri",
    basic: "Temel Hesap",
    yourActiveAccount: "Aktif hesabınız",
  },
  error404: {
    nothing: "Burada hiçbir şey yok gibi görünüyor",
    return: "Anasayfaya dön",
  },
  error500: {
    title: "Sistem Hatası",
    desc: "Bu sayfaya giriş yetkiniz yok!",
    return: "Anasayfaya dön",
  },
  swalMessages: {
    SESSION_TIME_OUT:
      "Oturum süresi doldu. Lütfen kullanıcı bilgilerinizle tekrar giriş yapınız.",

    CONFIRM_BUTTON_TEXT: "Tamam",
    API_ERROR_MESSAGE:
      "Teknik hata. Sunucuya bağlanılamıyor, lütfen tekrar deneyiniz.",
    ERROR_CODE: " Hata kodu: ",
    UNKNOWN_ERROR_MESSAGE: "Bilinmeyen bir hata oluştu.",
    ARE_YOU_SURE: "Emin misiniz?",
    SURE_DELETE_STAFF: "Bu personeli silmek istediğinize emin misiniz?",
    SURE_DELETE_POLL: "Bu anketi silmek istediğinize emin misiniz?",
    SURE_DELETE_INTERVIEW: "Bu mülakatı silmek istediğinize emin misiniz?",

    ERROR_GENERATE: "Doğrulama kodu gönderimi başarısız! Lütfen tekrar deneyiniz.",
    SUCCESS_GENERATE: "Doğrulama kodu başarıyla gönderildi!",

    ERROR_VERIFY: "Doğrulama başarısız! Lütfen tekrar deneyiniz.",
    SUCCESS_VERIFY: "Doğrulama başarılı!",
    ERROR_VERIFY_REMAINING_ATTEMPTS:
      "Deneme sınırınızı aştınız. Lütfen daha sonra tekrar deneyiniz.",
    TIME_ERROR: "Süre doldu. Lütfen tekrar giriş yapınız.",

    ERROR_UPDATE_USER:
      "Kullanıcı bilgileri güncellenemedi. Lütfen tekrar deneyiniz.",
    SUCCESS_UPDATE_USER: "Kişisel bilgileri güncelleme işlemi başarılı!",
    ERROR_CHANGE_TENANT_USER: "Teknik hata! Çiftlik değiştirme işlemi başarısız.",
    ERROR_ABOUT_USER: "Teknik hata! Kişisel bilgi yüklemesi başarısız.",

    ERROR_ADD_STAFF: "Teknik hata! Personel kayıt işlemi başarısız.",
    ERROR_DELETE_STAFF: "Teknik hata! Personel silme işlemi başarısız.",
    SUCCESS_DELETE_STAFF: "Personel başarıyla silindi!",
    ERROR_STAFF_LIST: "Teknik hata! Personel listeleme işlemi başarısız.",
    ERROR_INVITE_STAFF: "Teknik hata! Personel davet etme işlemi başarısız.",
    ERROR_POLL_LIST: "Teknik hata! Anket listeleme işlemi başarısız.",
    SUCCESS_INVITE_STAFF: "Personel başarıyla davet edildi!",
    SUCCESS_ACCEPT_INVITE: "Erişim izni başarıyla verildi.",
    ERROR_ACCEPT_INVITE:
      "Geçersiz davet linki, lütfen yöneticiniz ile iletişime geçiniz.",

    ERROR_PRODUCT_LIST: "Teknik hata! Modül listeleme işlemi başarısız.",
    SUCCESS_BUY_PRODUCT: "Tebrikler, modülü başarıyla satın aldınız!",
    ERROR_BUY_PRODUCT:
      "İşlem başarısız! Modül satın alınamadı. Lütfen yönetici ile iletişime geçin.",
    SUCCESS_UPDATE_PRODUCT_MODEL_SETTING:
      "Modül modeli başarıyla değiştirildi.",
    ERROR_UPDATE_PRODUCT_MODEL_SETTING:
      "İşlem başarısız! Modül modeli değiştirilemedi.",

    ERROR_DELETE_POLL: "Teknik hata! Anket silme işlemi başarısız.",
    SUCCESS_DELETE_POLL: "Anket başarıyla silindi!",
    ERROR_CREATE_POLL: "Teknik hata! Anket oluşturma işlemi başarısız.",
    SUCCESS_CREATE_POLL: "Anket başarıyla oluşturuldu!",
    ERROR_UPDATE_POLL: "Teknik hata! Anket güncelleme işlemi başarısız.",
    SUCCESS_UPDATE_POLL: "Anket başarıyla güncellendi!",
    ERROR_INVITE_USER: "Teknik hata! Kullanıcıları ankete davet etme işlemi başarısız.",
    SUCCESS_INVITE_USER: "Anket davet linki başarıyla gönderildi!",
    SUCCESS_INVITE_USERS: "Anket davet linkleri başarıyla gönderildi!",
    ERROR_FILE_UPLOAD: "Teknik hata! Dosya gönderme işlemi başarısız.",
    SUCCESS_FILE_UPLOAD: "Dosya başarıyla gönderildi!",
    ERROR_POLL_DETAIL: "Teknik hata! Anket detayı görüntüleme işlemi başarısız.",
    ERROR_POLL_STATISTICS_LIST: "Teknik hata! Geçmiş anketleri listeleme işlemi başarısız.",
    ERROR_POLL_STATISTICS_MESSAGE_LIST:
      "Teknik hata! Anket log mesajlarını görüntüleme işlemi başarısız.",
    ERROR_POLL_STATISTICS_SESSION_DETAIL:
      "Teknik hata! Anket detayı ve istatistik görüntüleme işlemi başarısız.",
    ERROR_POLL_STATISTICS_RESPONSE_LIST:
      "Teknik hata! Anket cevapları görüntüleme işlemi başarısız.",

    ERROR_INTERVIEW_LIST: "Teknik hata! Mülakat listeleme işlemi başarısız.",
    ERROR_INTERVIEW_DETAIL: "Teknik hata! Mülakat detayı görüntüleme işlemi başarısız.",
    ERROR_DELETE_INTERVIEW: "Teknik hata! Mülakat silme işlemi başarısız.",
    SUCCESS_DELETE_INTERVIEW: "Mülakat başarıyla silindi!",
    ERROR_CREATE_INTERVIEW: "Teknik hata! Mülakat oluşturma işlemi başarısız.",
    SUCCESS_CREATE_INTERVIEW: "Mülakat başarıyla oluşturuldu!",
    ERROR_UPDATE_INTERVIEW: "Teknik hata! Mülakat güncelleme işlemi başarısız.",
    SUCCESS_UPDATE_INTERVIEW: "Mülakat başarıyla güncellendi!",
    ERROR_ADD_CANDIDATE: "Teknik hata! Mülakata aday davet etme işlemi başarısız.",
    SUCCESS_ADD_CANDIDATE: "Aday başarıyla mülakata davet edildi.",

    ERROR_SEND_MESSAGE:
      " Limitiniz dolduğu için yeni oturum başlatamıyorsunuz. Lütfen hesabınızı yükseltin.",
    SUCCESS_SEND_MESSAGE: "Tebrikler, mesaj başarıyla gönderildi.",
    ERROR_GET_SESSION_LIST:
      " Teknik hata, konuşma geçmişi bilgisine ulaşılamadı! ",
    SUCCESS_RETRY_ANSWER: "Tebrikler, cevap yeniden oluşturuldu.",
    ERROR_RETRY_ANSWER:
      " Limitiniz dolduğu için cevap oluşturamıyorsunuz. Lütfen hesabınızı yükseltin.",

    SESSION_CLOSED:
      "Sohbet tamamlanmıştır. Yeni sohbet başlamak için lütfen sol menüde yer alan 'Yeni Konuşma Başlat' butonuna tıklayınız.",

    ERROR_GET_STATISTICS_LIST: "Teknik hata! İstatistik yükleme işlemi başarısız.",
  },
  customErrorMessages: {
    useCase: {
      error: "Usecase error.",
    },
    mapper: {
      error: "Mapper error.",
    },
    controller: {
      error: "Controller error.",
    },
    repository: {
      error: "Repository error.",
    },
    aggregateRoot: {
      error: "Aggregate root error.",
    },
    domainEvent: {
      error: "Domain Event error.",
    },
    email: {
      required: "Lütfen e-posta adresinizi giriniz.",
      invalid: "Geçerli bir e-posta adresi giriniz.",
    },
    password: {
      required: "Lütfen şifrenizi giriniz.",
      min: "Şifre en az 6 karakter olmalıdır.",
      match: "Şifreleriniz birbiriyle aynı olmalıdır.",
    },
    identity: {
      required: "Lütfen tc kimlik numaranızı giriniz.",
      length: "Tc kimlik numarası 11 haneden oluşmalıdır.",
    },
    credential: {
      required: "Lütfen e-posta adresinizi ve şifrenizi giriniz.",
      credential: "Geçersiz bir e-posta adresi veya şifre girdiniz.",
    },
  },
  validators_error: {
    required: "Lütfen boş bırakmayınız.",
    typeError: "Lütfen uygun tipte veri giriniz.",
    email: "Geçerli bir e-posta adresi giriniz.",
    sameEmails: "Bu e-posta adresini daha önce girdiniz.",
    minlength: " karakterden az olamaz.",
    maxlength: " karakterden fazla olamaz.",
    zeroValue: "Değer 0'dan az olamaz.",
    phoneNumberFull: "Lütfen telefon numaranızın tamamını giriniz.",
    requiredCode: "Lütfen doğrulama kodunu boş bırakmayınız.",
    codeInvalid: "Lütfen 6 haneli doğrulama kodunu giriniz.",
    min18age: "18 yaşından küçük olamaz.",
    startDateBiggerEndDate: "Başlangıç tarihi, bitiş tarihinden erken olamaz.",
  },
};
