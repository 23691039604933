import { SessionListRepository } from "../repository/SessionListRepository";
import { Result } from "@/core/utility/ResultType";
import { SessionListMapper } from "../mapper/SessionListMapper";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/application/constants/Usecase";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { ISessionListUseCase } from "@/domain/hotel-management/session-list/usecase/ISessionListService";
import { SessionListModel } from "@/domain/hotel-management/session-list/model/SessionListModel";
import { ISessionListRepository } from "@/domain/hotel-management/session-list/repository/ISessionListRepository";

export class SessionListUseCase implements ISessionListUseCase {
  sessionListRepository: ISessionListRepository = new SessionListRepository();
  sessionListMapper = new SessionListMapper();

  constructor() {
  }

  async execute(model: SessionListModel): Promise<Result<SessionListModel>> {
    try {
      const dto = this.sessionListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<SessionListModel>(dto.getError());
      } else {
        const response = await this.sessionListRepository.sessionList(dto);

        if (response.isSuccess) {
          const domain = this.sessionListMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<SessionListModel>(domain.getValue());
        } else {
          return Result.fail<SessionListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<SessionListModel>(
        new UseCaseException(new UnknownCause(USECASE.SESSION_LIST))
      );
    }
  }
}
