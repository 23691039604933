import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";
import { StatisticPollListModel } from "@/domain/pollster/poll-statistic/poll-list/model/PollListModel";
import { StatisticPollListResponseDto } from "@/domain/pollster/poll-statistic/poll-list/dto/PollListResponseDto";
import { StatisticPollListRequestDto } from "@/domain/pollster/poll-statistic/poll-list/dto/PollListRequestDto";

const paginationMapper = new PaginationMapper();

export class StatisticPollListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: StatisticPollListResponseDto): Result<StatisticPollListModel[]> {
    try {
      const statisticPollList: StatisticPollListModel[] = [];

      if (dto.data.length > 0) {
        for (const item of dto.data) {
          const statisticPollListModel: StatisticPollListModel = {
            poll: {
              id: Number(item.id),
              title: item.attributes.title,
              createdAt: item.attributes.createdAt,
              endDate: item.attributes.endDate,
              sessionDuration: item.attributes.sessionDuration,
              surveyIsPrivate: item.attributes.surveyIsPrivate,
            },
            page: 0,
            pageSize: 0,
            pagination: dto.meta.pagination,
          };

          statisticPollList.push(statisticPollListModel);
        }
        return Result.ok(statisticPollList);
      }
      return Result.ok(statisticPollList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.STATISTIC.POLL_LIST))
      );
    }
  }

  mapToDTO(domain: StatisticPollListModel): Result<StatisticPollListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const statisticPollListRequest = new StatisticPollListRequestDto(pagiRequest);

      return Result.ok(statisticPollListRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.STATISTIC.POLL_LIST))
      );
    }
  }
}
