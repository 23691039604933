import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { BuyProductRequestDto } from "@/domain/product/buy-product/dto/BuyProductRequestDto";
import { BuyProductResponseDto } from "@/domain/product/buy-product/dto/BuyProductResponseDto";
import { BuyProductModel } from "@/domain/product/buy-product/model/BuyProductModel";

export class BuyProductMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: BuyProductResponseDto): Result<BuyProductModel> {
    try {
      const buyProductModel: BuyProductModel = {
        productPackageId: dto.data.id,
        name: dto.data.attributes.name,
        price: dto.data.attributes.price,
        currency: dto.data.attributes.currency,
        description: dto.data.attributes.description,
        period: dto.data.attributes.period,
        quota: dto.data.attributes.quota,
      };

      return Result.ok(buyProductModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.PRODUCT.BUY_PRODUCT))
      );
    }
  }

  mapToDTO(domain: BuyProductModel): Result<BuyProductRequestDto> {
    try {
      const buyProductRequest = new BuyProductRequestDto(
        domain.productPackageId,
      );

      return Result.ok(buyProductRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.PRODUCT.BUY_PRODUCT))
      );
    }
  }
}
