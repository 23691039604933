import ChangeTenantEstateRepository from "../repository/ChangeTenantEstateRepository";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { ChangeTenantEstateMapper } from "../mapper/ChangeTenantEstateMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { UserManager } from "@/infrastructure/helper/UserManager";
import { IChangeTenantEstateUseCase } from "@/domain/auth/change-tenant-estate/usecase/IChangeTenantEstateUsecase";
import { ChangeTenantEstateModel } from "@/domain/auth/change-tenant-estate/model/ChangeTenantEstateModel";
import { ChangeTenantEstateResponseDto } from "@/domain/auth/change-tenant-estate/dto/ChangeTenantEstateResponseDto";
import { IChangeTenantEstateRepository } from "@/domain/auth/change-tenant-estate/repository/IChangeTenantEstateRepository";

export class ChangeTenantEstateUseCase implements IChangeTenantEstateUseCase {
  changeTenantEstateRepository: IChangeTenantEstateRepository = new ChangeTenantEstateRepository();
  changeTenantEstateMapper = new ChangeTenantEstateMapper();
  userManager = new UserManager();

  constructor(
  ) {
  }

  async execute(
    model: ChangeTenantEstateModel
  ): Promise<Result<ChangeTenantEstateModel>> {
    try {
      const dto = this.changeTenantEstateMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<ChangeTenantEstateModel>(dto.getError());
      } else {
        const response =
          await this.changeTenantEstateRepository.changeTenantEstate(dto);

        if (response.isSuccess) {
          const value: ChangeTenantEstateResponseDto = response.getValue();

          this.userManager.saveToken(value.jwt);
          this.userManager.setActiveTenantName(value.data.attributes.activeTenantEstate);
          this.userManager.saveRole(value.data.attributes.activeTenantEstate.data.attributes.tenantRole.data.attributes.shortCode);

          const changeTenantEstateModel =
            this.changeTenantEstateMapper.mapToDomain(value);
          return Result.ok<ChangeTenantEstateModel>(
            changeTenantEstateModel.getValue()
          );
        } else {
          return Result.fail<ChangeTenantEstateModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<ChangeTenantEstateModel>(
        new UseCaseException(new UnknownCause(USECASE.CHANGE_TENANT_ESTATE))
      );
    }
  }
}
