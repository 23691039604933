import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import JobPostingListService from "../service/JobPostingListService";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IJobPostingListRepository } from "@/domain/interview/job-posting-list/repository/IJobPostingListRepository";
import { JobPostingListRequestDto } from "@/domain/interview/job-posting-list/dto/JobPostingListRequestDto";
import { JobPostingListResponseDto } from "@/domain/interview/job-posting-list/dto/JobPostingListResponseDto";

const jobPostingListService = new JobPostingListService();

export class JobPostingListRepository implements IJobPostingListRepository {
  async jobPostingList(
    request: JobPostingListRequestDto
  ): Promise<Result<JobPostingListResponseDto>> {
    try {
      return await jobPostingListService
        .getList(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<JobPostingListResponseDto>(response.getValue());
          } else {
            return Result.fail<JobPostingListResponseDto>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<JobPostingListResponseDto>(
            new BadRequestException(error)
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new RepositoryException(new UnknownCause(REPOSITORY.INTERVIEW.INTERVIEW_LIST))
      );
    }
  }
}
