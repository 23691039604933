import { AddCandidateRepository } from "../repository/AddCandidateRepository";
import { AddCandidateMapper } from "../mapper/AddCandidateMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAddCandidateUseCase } from "@/domain/interview/add-candidate/usecase/IAddCandidateUsecase";
import { IAddCandidateRepository } from "@/domain/interview/add-candidate/repository/IAddCandidateRepository";
import { AddCandidateModel } from "@/domain/interview/add-candidate/model/AddCandidateModel";

export class AddCandidateUsecase implements IAddCandidateUseCase {
  addCandidateRepository : IAddCandidateRepository = new AddCandidateRepository();
  addCandidateMapper = new AddCandidateMapper();

  constructor() {
  }

  async execute(model: AddCandidateModel): Promise<Result<AddCandidateModel>> {
    try {
      const dto = this.addCandidateMapper.mapToDTO(model).getValue();

      if (dto.isFailure) {
        return Result.fail<AddCandidateModel>(dto.getError());
      } else {
        const response = await this.addCandidateRepository.addCandidate(dto);

        if (response.isSuccess) {
          const domain = this.addCandidateMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<AddCandidateModel>(domain.getValue());
        } else {
          return Result.fail<AddCandidateModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AddCandidateModel>(
        new UseCaseException(new UnknownCause(USECASE.INTERVIEW.ADD_CANDIDATE))
      );
    }
  }
}
