import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { IPollMessageListService } from "@/domain/pollster/poll-statistic/poll-message-list/service/IPollMessageListService";
import { PollMessageListRequestDto } from "@/domain/pollster/poll-statistic/poll-message-list/dto/PollMessageListRequestDto";
import { PollMessageListResponseDto } from "@/domain/pollster/poll-statistic/poll-message-list/dto/PollMessageListResponseDto";

export default class PollMessageListService implements IPollMessageListService {
  async getList(
    request: PollMessageListRequestDto
  ): Promise<Result<PollMessageListResponseDto>> {
    try {
      return await instance
        .get(API_ROUTE.SURVEYS_SESSIONS + request.id + API_ROUTE.MESSAGES)
        .then((response) => {
          return Result.ok<PollMessageListResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<PollMessageListResponseDto>(
            new BadRequestException(
              new BadRequestCause(
                i18n.global.t(
                  SWAL_MESSAGES.ERROR.ERROR_POLL_STATISTICS.MESSAGE_LIST
                )
              )
            )
          );
        });
    } catch (error) {
      return Result.fail<string>(
        new ServiceException(
          new UnknownCause(SERVICE.POLLSTER.STATISTIC.POLL_MESSAGE_LIST)
        )
      );
    }
  }
}
