import { ProductListRepository } from "../repository/ProductListRepository";
import { Result } from "@/core/utility/ResultType";
import { ProductListMapper } from "../mapper/ProductListMapper";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/application/constants/Usecase";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IProductListUseCase } from "@/domain/product/product-list/usecase/IProductListUsecase";
import { ProductListModel } from "@/domain/product/product-list/model/ProductListModel";

export class ProductListUseCase implements IProductListUseCase {
  productListRepository = new ProductListRepository();
  productListMapper = new ProductListMapper();

  constructor() {
  }

  async execute(model: ProductListModel): Promise<Result<ProductListModel>> {
    try {
      const dto = this.productListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<ProductListModel>(dto.getError());
      } else {
        const response = await this.productListRepository.productList(dto);

        if (response.isSuccess) {
          const domain = this.productListMapper.mapToDomain(response.getValue());
          return Result.ok<ProductListModel>(domain.getValue());
        } else {
          return Result.fail<ProductListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<ProductListModel>(
        new UseCaseException(new UnknownCause(USECASE.PRODUCT.PRODUCT_LIST))
      );
    }
  }
}
