import { Result } from "@/core/utility/ResultType";
import instance from "@/infrastructure/network/axios/AxiosService";
import { ServiceException } from "@/core/exception/base/ServiceException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { BadRequestCause } from "@/core/cause/BadRequestCause";
import i18n from "@/presentation/language/i18n";
import { API_ROUTE } from "@/application/constants/ApiRoutes";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { SERVICE } from "@/application/constants/Service";
import { IJobPostingDetailService } from "@/domain/interview/job-posting-detail/service/IJobPostingDetailService";
import { JobPostingDetailRequestDto } from "@/domain/interview/job-posting-detail/dto/JobPostingDetailRequestDto";
import { JobPostingDetailResponseDto } from "@/domain/interview/job-posting-detail/dto/JobPostingDetailResponseDto";

export default class JobPostingDetailService implements IJobPostingDetailService {
  async get(
    request: JobPostingDetailRequestDto
  ): Promise<Result<JobPostingDetailResponseDto>> {
    try {
      return await instance
        .get<JobPostingDetailResponseDto>(API_ROUTE.INTERVIEW + request.id)
        .then((response) => {
          return Result.ok<JobPostingDetailResponseDto>(response.data);
        })
        .catch(() => {
          return Result.fail<JobPostingDetailResponseDto>(
            new BadRequestException(new BadRequestCause(i18n.global.t(SWAL_MESSAGES.ERROR.ERROR_INTERVIEW_DETAIL)))
          );
        });
    } catch (error) {
      return Result.fail<JobPostingDetailResponseDto>(
        new ServiceException(new UnknownCause(SERVICE.INTERVIEW.INTERVIEW_DETAIL))
      );
    }
  }
}
