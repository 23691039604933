import { PollDetailRepository } from "../repository/PollDetailRepository";
import { PollDetailMapper } from "../mapper/PollDetailMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { PollDetailModel } from "@/domain/pollster/poll-detail/model/PollDetailModel";
import { IPollDetailUseCase } from "@/domain/pollster/poll-detail/usecase/IPollDetailUseCase";
import { IPollDetailRepository } from "@/domain/pollster/poll-detail/repository/IPollDetailRepository";

export class PollDetailUseCase implements IPollDetailUseCase {
  getPollDetailRepository : IPollDetailRepository = new PollDetailRepository();
  getPollDetailMapper = new PollDetailMapper ();

  constructor() {
  }

  async execute(model: PollDetailModel): Promise<Result<PollDetailModel>> {
    try {
      const dto = this.getPollDetailMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<PollDetailModel>(dto.getError());
      } else {
        const response = await this.getPollDetailRepository.getPollDetail(dto);

        if (response.isSuccess) {
          const domain = this.getPollDetailMapper.mapToDomain(
            response.getValue()
          );
          return Result.ok<PollDetailModel>(domain.getValue());
        } else {
          return Result.fail<PollDetailModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<PollDetailModel>(
        new UseCaseException(new UnknownCause(USECASE.POLLSTER.POLL_DETAIL))
      );
    }
  }
}
