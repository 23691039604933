import { AcceptInviteRepository } from "../repository/AcceptInviteRepository";
import { AcceptInviteMapper } from "../mapper/AcceptInviteMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IAcceptInviteUseCase } from "@/domain/staff/invite-staff/accept/usecase/IAcceptInviteUseCase";
import { AcceptInviteModel } from "@/domain/staff/invite-staff/accept/model/AcceptInviteModel";
import { IAcceptInviteRepository } from "@/domain/staff/invite-staff/accept/repository/IAcceptInviteRepository";

export class AcceptInviteUseCase implements IAcceptInviteUseCase {
  acceptInviteRepository : IAcceptInviteRepository = new AcceptInviteRepository();
  acceptInviteMapper = new AcceptInviteMapper();

  constructor() {
  }

  async execute(model: AcceptInviteModel): Promise<Result<AcceptInviteModel>> {
    try {
      const dto = this.acceptInviteMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<AcceptInviteModel>(dto.getError());
      } else {
        const response = await this.acceptInviteRepository.acceptInvite(dto);

        if (response.isSuccess) {
          const domain = this.acceptInviteMapper.mapToDomain(
            response.getValue()
          );

          return Result.ok<AcceptInviteModel>(domain.getValue());
        } else {
          return Result.fail<AcceptInviteModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<AcceptInviteModel>(
        new UseCaseException(new UnknownCause(USECASE.STAFF.ACCEPT_INVITE))
      );
    }
  }
}
