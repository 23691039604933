import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { GenerateOTPUsecase } from "@/application/auth/generate-otp/usecase/GenerateOTPUseCase";
import { GenerateOTPModel } from "@/domain/auth/generate-otp/model/GenerateOTPModel";
import { VerifyOTPRepository } from "@/application/auth/verify-otp/repository/VerifyOTPRepository";
import { VerifyOTPUsecase } from "@/application/auth/verify-otp/usecase/VerifyOTPUseCase";
import { VerifyOTPModel } from "@/domain/auth/verify-otp/model/VerifyOTPModel";

export function createOTPController() {
  const generateOTPUseCase = new GenerateOTPUsecase();
  const verifyOTPUseCase = new VerifyOTPUsecase();
  return new OTPController(generateOTPUseCase, verifyOTPUseCase);
}

export class OTPController implements IController {
  constructor(
    private generateOTPUseCase: GenerateOTPUsecase,
    private verifyOTPUseCase: VerifyOTPUsecase
  ) {}

  async generateOTP(
    generateOTPModel: GenerateOTPModel
  ): Promise<Result<GenerateOTPModel>> {
    const response = await this.generateOTPUseCase.execute(generateOTPModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<GenerateOTPModel>(response.getError());
    }
  }

  async verifyOTP(
    verifyOTPModel: VerifyOTPModel
  ): Promise<Result<VerifyOTPModel>> {
    const response = await this.verifyOTPUseCase.execute(verifyOTPModel);

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<VerifyOTPModel>(response.getError());
    }
  }
}
