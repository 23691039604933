import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";
import { CountryListRequestDto } from "@/domain/country/country-list/dto/CountryListRequestDto";
import { CountryListResponseDto } from "@/domain/country/country-list/dto/CountryListResponseDto";
import { CountryListModel } from "@/domain/country/country-list/model/CountryListModel";

const paginationMapper = new PaginationMapper();

export class CountryListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: CountryListResponseDto): Result<CountryListModel[]> {
    try {
      const countryList: CountryListModel[] = [];

      if (dto.data.length > 0) {
        for (const item of dto.data) {
          const countryListModel: CountryListModel = {
            country: {
              id: item.id,
              name: item.attributes.name,
              flagName: item.attributes.flag?.data.attributes.name,
              flagWidth: item.attributes.flag?.data.attributes.width,
              flagHeight: item.attributes.flag?.data.attributes.height,
              flagUrl: item.attributes.flag?.data.attributes.url,
              previewUrl: item.attributes.flag?.data.attributes.previewUrl,
            },
            page: 0,
            pageSize: 0,
            pagination: dto.meta.pagination,
          };

          countryList.push(countryListModel);
        }
      }
      return Result.ok(countryList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.COUNTRY_LIST))
      );
    }
  }

  mapToDTO(domain: CountryListModel): Result<CountryListRequestDto> {
    try {
     // const i18n = useI18n();
     
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

        //       const countryRequest = new CountryListRequestDto(pagiRequest, i18n.locale.value);

      const countryRequest = new CountryListRequestDto(pagiRequest, "tr");

      return Result.ok(countryRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.COUNTRY_LIST))
      );
    }
  }
}
