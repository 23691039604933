import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";
import { PaginationMapper } from "@/domain/base/pagination/mapper/PaginationMapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";
import { PollListRequestDto } from "@/domain/pollster/poll-list/dto/PollListRequestDto";
import { PollListResponseDto } from "@/domain/pollster/poll-list/dto/PollListResponseDto";
import { PollListModel } from "@/domain/pollster/poll-list/model/PollListModel";

const paginationMapper = new PaginationMapper();

export class PollListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: PollListResponseDto): Result<PollListModel[]> {
    try {
      const pollList: PollListModel[] = [];

      if (dto.data.length > 0) {
        for (const item of dto.data) {
          const pollListModel: PollListModel = {
            poll: {
              id: Number(item.id),
              title: item.attributes.title,
              description: item.attributes.description,
              status: item.attributes.status,
              startDate: item.attributes.startDate,
              endDate: item.attributes.endDate,
              isPrivate: item.attributes.isPrivate,
              sessionCount: item.attributes.sessionCount,
              surveyQuestionsCount: item.attributes.surveyQuestionsCount,
            },
            page: 0,
            pageSize: 0,
            pagination: dto.meta.pagination,
          };

          pollList.push(pollListModel);
        }
        return Result.ok(pollList);
      }
      return Result.ok(pollList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.POLL_LIST))
      );
    }
  }

  mapToDTO(domain: PollListModel): Result<PollListRequestDto> {
    try {
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );
      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();

      const PollListRequest = new PollListRequestDto(pagiRequest, domain.poll.status);

      return Result.ok(PollListRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.POLLSTER.POLL_LIST))
      );
    }
  }
}
