import { PollListRepository } from "../repository/PollListRepository";
import { Result } from "@/core/utility/ResultType";
import { PollListMapper } from "../mapper/PollListMapper";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { USECASE } from "@/application/constants/Usecase";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IPollListUseCase } from "@/domain/pollster/poll-list/usecase/IPollListUsecase";
import { PollListModel } from "@/domain/pollster/poll-list/model/PollListModel";
import { IPollListRepository } from "@/domain/pollster/poll-list/repository/IPollListRepository";

export class PollListUseCase implements IPollListUseCase {
  pollListRepository : IPollListRepository = new PollListRepository();
  pollListMapper = new PollListMapper();

  constructor() {
  }

  async execute(model: PollListModel): Promise<Result<PollListModel>> {
    try {
      const dto = this.pollListMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<PollListModel>(dto.getError());
      } else {
        const response = await this.pollListRepository.pollList(dto);

        if (response.isSuccess) {
          const domain = this.pollListMapper.mapToDomain(response.getValue());
          return Result.ok<PollListModel>(domain.getValue());
        } else {
          return Result.fail<PollListModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<PollListModel>(
        new UseCaseException(new UnknownCause(USECASE.POLLSTER.POLL_LIST))
      );
    }
  }
}
