import { RetryAnswerRepository } from "../repository/RetryAnswerRepository";
import { RetryAnswerModel } from "@/domain/hotel-management/retry-answer/model/RetryAnswerModel";
import { IRetryAnswerUseCase } from "@/domain/hotel-management/retry-answer/usecase/IRetryAnswerUseCase";
import { RetryAnswerMapper } from "../mapper/RetryAnswerMapper";
import { Result } from "@/core/utility/ResultType";
import { USECASE } from "@/application/constants/Usecase";
import { UseCaseException } from "@/core/exception/base/UseCaseException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { IRetryAnswerRepository } from "@/domain/hotel-management/retry-answer/repository/IRetryAnswerRepository";

export class RetryAnswerUseCase implements IRetryAnswerUseCase {
  retryAnswerRepository: IRetryAnswerRepository = new RetryAnswerRepository();
  retryAnswerMapper = new RetryAnswerMapper();

  constructor() {
  }

  async execute(model: RetryAnswerModel): Promise<Result<RetryAnswerModel>> {
    try {
      const dto = this.retryAnswerMapper.mapToDTO(model).getValue();
      if (dto.isFailure) {
        return Result.fail<RetryAnswerModel>(dto.getError());
      } else {
        const response = await this.retryAnswerRepository.retryAnswer(dto);

        if (response.isSuccess) {
          const domain = this.retryAnswerMapper.mapToDomain(
            response.getValue()
          );

          return Result.ok<RetryAnswerModel>(domain.getValue());
        } else {
          return Result.fail<RetryAnswerModel>(response.getError());
        }
      }
    } catch (error) {
      return Result.fail<RetryAnswerModel>(
        new UseCaseException(new UnknownCause(USECASE.RETRY_ANSWER))
      );
    }
  }
}
