import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { Result } from "@/core/utility/ResultType";
import { MapperException } from "@/core/exception/base/MapperException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MAPPER } from "@/application/constants/Mapper";
import { UpdateJobPostingModel } from "@/domain/interview/update-job-posting/model/UpdateJobPostingModel";
import { UpdateJobPostingRequestDto } from "@/domain/interview/update-job-posting/dto/UpdateJobPostingRequestDto";

export class UpdateJobPostingMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: any): Result<UpdateJobPostingModel> {
    try {
        const updateInterviewModel: UpdateJobPostingModel = {
          ok: dto.ok,
        };
        return Result.ok(updateInterviewModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.UPDATE_INTERVIEW))
      );
    }
  }

  mapToDTO(domain: UpdateJobPostingModel): Result<UpdateJobPostingRequestDto> {
    try {
      const pollRequest: UpdateJobPostingRequestDto = {
        id: domain.id,
        data: {
          endDate: domain.endDate
        }
      };

      return Result.ok(pollRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.INTERVIEW.UPDATE_INTERVIEW))
      );
    }
  }
}
