import { Result } from "@/core/utility/ResultType";
import { IController } from "@/core/base/IController";
import { StatisticUsecase } from "@/application/statistic/usecase/StatisticUsecase";
import { StatisticModel } from "@/domain/statistic/model/StatisticModel";

export function createStatisticController() {
  const statisticUseCase = new StatisticUsecase();
  return new StatisticController(statisticUseCase);
}

export class StatisticController implements IController {
  constructor(private statisticUseCase: StatisticUsecase) {}

  async getStatistic(): Promise<Result<StatisticModel>> {
    const response = await this.statisticUseCase.execute();

    if (response.isSuccess) {
      return response;
    } else {
      return Result.fail<StatisticModel>(response.getError());
    }
  }
}
