export const SWAL_MESSAGES = {
  CONFIRM_BUTTON_TEXT: "swalMessages.CONFIRM_BUTTON_TEXT",
  SURE_DELETE_STAFF: "swalMessages.SURE_DELETE_STAFF",
  SURE_DELETE_POLL: "swalMessages.SURE_DELETE_POLL",
  SURE_DELETE_INTERVIEW: "swalMessages.SURE_DELETE_INTERVIEW",
  ARE_YOU_SURE: "swalMessages.ARE_YOU_SURE",
  SESSION_CLOSED: "swalMessages.SESSION_CLOSED",

  SUCCESS: {
    SUCCESS_GENERATE: "swalMessages.SUCCESS_GENERATE",

    SUCCESS_VERIFY: "swalMessages.SUCCESS_VERIFY",
    SUCCESS_UPDATE_USER: "swalMessages.SUCCESS_UPDATE_USER",

    SUCCESS_INVITE_STAFF: "swalMessages.SUCCESS_INVITE_STAFF",
    SUCCESS_ACCEPT_INVITE: "swalMessages.SUCCESS_ACCEPT_INVITE",
    SUCCESS_DELETE_STAFF: "swalMessages.SUCCESS_DELETE_STAFF",

    SUCCESS_BUY_PRODUCT: "swalMessages.SUCCESS_BUY_PRODUCT",
    SUCCESS_UPDATE_PRODUCT_MODEL_SETTING:
      "swalMessages.SUCCESS_UPDATE_PRODUCT_MODEL_SETTING",

    SUCCESS_DELETE_POLL: "swalMessages.SUCCESS_DELETE_POLL",
    SUCCESS_CREATE_POLL: "swalMessages.SUCCESS_CREATE_POLL",
    SUCCESS_UPDATE_POLL: "swalMessages.SUCCESS_UPDATE_POLL",
    SUCCESS_INVITE_USER: "swalMessages.SUCCESS_INVITE_USER",
    SUCCESS_INVITE_USERS: "swalMessages.SUCCESS_INVITE_USERS",
    SUCCESS_FILE_UPLOAD: "swalMessages.SUCCESS_FILE_UPLOAD",

    SUCCESS_DELETE_INTERVIEW: "swalMessages.SUCCESS_DELETE_INTERVIEW",
    SUCCESS_CREATE_INTERVIEW: "swalMessages.SUCCESS_CREATE_INTERVIEW",
    SUCCESS_UPDATE_INTERVIEW: "swalMessages.SUCCESS_UPDATE_INTERVIEW",
    SUCCESS_ADD_CANDIDATE: "swalMessages.SUCCESS_ADD_CANDIDATE",

    SUCCESS_SEND_MESSAGE: "swalMessages.SUCCESS_SEND_MESSAGE",
    SUCCESS_RETRY_ANSWER: "swalMessages.SUCCESS_RETRY_ANSWER",
  },

  ERROR: {
    SESSION_TIME_OUT: "swalMessages.SESSION_TIME_OUT",
    API_ERROR_MESSAGE: "swalMessages.API_ERROR_MESSAGE",
    ERROR_CODE: "swalMessages.ERROR_CODE",
    UNKNOWN_ERROR_MESSAGE: "swalMessages.UNKNOWN_ERROR_MESSAGE",

    ERROR_GENERATE: "swalMessages.ERROR_GENERATE",

    ERROR_VERIFY: "swalMessages.ERROR_VERIFY",
    ERROR_VERIFY_REMAINING_ATTEMPTS:
      "swalMessages.ERROR_VERIFY_REMAINING_ATTEMPTS",

    TIME_ERROR: "swalMessages.TIME_ERROR",

    ERROR_UPDATE_USER: "swalMessages.ERROR_UPDATE_USER",
    ERROR_CHANGE_TENANT_USER: "swalMessages.ERROR.ERROR_CHANGE_TENANT_USER",
    ERROR_ABOUT_USER: "swalMessages.ERROR_ABOUT_USER",

    ERROR_ADD_STAFF: "swalMessages.ERROR_ADD_STAFF",
    ERROR_DELETE_STAFF: "swalMessages.ERROR_DELETE_STAFF",
    ERROR_STAFF_LIST: "swalMessages.ERROR_STAFF_LIST",
    ERROR_POLL_LIST: "swalMessages.ERROR_POLL_LIST",
    ERROR_INVITE_STAFF: "swalMessages.ERROR_INVITE_STAFF",
    ERROR_ACCEPT_INVITE: "swalMessages.ERROR_ACCEPT_INVITE",

    ERROR_PRODUCT_LIST: "swalMessages.ERROR_PRODUCT_LIST",
    ERROR_BUY_PRODUCT: "swalMessages.ERROR_BUY_PRODUCT",
    ERROR_UPDATE_PRODUCT_MODEL_SETTING:
      "swalMessages.ERROR_UPDATE_PRODUCT_MODEL_SETTING",

    ERROR_DELETE_POLL: "swalMessages.ERROR_DELETE_POLL",
    ERROR_CREATE_POLL: "swalMessages.ERROR_CREATE_POLL",
    ERROR_UPDATE_POLL: "swalMessages.ERROR_UPDATE_POLL",
    ERROR_POLL_DETAIL: "swalMessages.ERROR_POLL_DETAIL",
    ERROR_POLL_STATISTICS: {
      LIST: "swalMessages.ERROR_POLL_STATISTICS_LIST",
      MESSAGE_LIST: "swalMessages.ERROR_POLL_STATISTICS_MESSAGE_LIST",
      SESSION_DETAIL: "swalMessages.ERROR_POLL_STATISTICS_SESSION_DETAIL",
      RESPONSE_LIST: "swalMessages.ERROR_POLL_STATISTICS_RESPONSE_LIST"
    },
    ERROR_INVITE_USER: "swalMessages.ERROR_INVITE_USER",
    ERROR_FILE_UPLOAD: "swalMessages.ERROR_FILE_UPLOAD",

    ERROR_DELETE_INTERVIEW: "swalMessages.ERROR_DELETE_INTERVIEW",
    ERROR_CREATE_INTERVIEW: "swalMessages.ERROR_CREATE_INTERVIEW",
    ERROR_UPDATE_INTERVIEW: "swalMessages.ERROR_UPDATE_INTERVIEW",
    ERROR_INTERVIEW_LIST: "swalMessages.ERROR_INTERVIEW_LIST",
    ERROR_INTERVIEW_DETAIL: "swalMessages.ERROR_INTERVIEW_DETAIL",
    ERROR_ADD_CANDIDATE: "swalMessages.ERROR_ADD_CANDIDATE",

    ERROR_SEND_MESSAGE: "swalMessages.ERROR_SEND_MESSAGE",
    ERROR_GET_SESSION_LIST: "swalMessages.ERROR_GET_SESSION_LIST",
    ERROR_RETRY_ANSWER: "swalMessages.ERROR_RETRY_ANSWER",

    ERROR_GET_STATISTICS_LIST: "swalMessages.ERROR_GET_STATISTICS_LIST",
  },
};
