import { BaseDTO } from "@/core/base/BaseDTO";
import { PaginationRequestDto } from "@/domain/base/pagination/dto/PaginationRequestDto";

export class JobPostingListRequestDto extends BaseDTO {
  pagination: PaginationRequestDto;
  status?: string;

  constructor(
    pagination: PaginationRequestDto,
    status?: string
  ) {
    super();
    this.pagination = pagination;
    this.status = status;
  }

  toQueryString(): string {
    let queryString = `?pagination[page]=${this.pagination.pagination.page}&pagination[pageSize]=${this.pagination.pagination.pageSize}`;

    if (this.status) {
      queryString += `&status=${this.status}`;
    }
    
    return queryString;
  }
}
