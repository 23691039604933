import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { GenerateOTPRequestDto } from "@/domain/auth/generate-otp/dto/GenerateOTPRequestDto";
import { GenerateOTPResponseDto } from "@/domain/auth/generate-otp/dto/GenerateOTPResponseDto";
import { GenerateOTPModel } from "@/domain/auth/generate-otp/model/GenerateOTPModel";

export class GenerateOTPMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: GenerateOTPResponseDto): Result<GenerateOTPModel> {
    try {
      const generateOTPModel: GenerateOTPModel = {
        expireTime: dto.data.attributes.expireTime,
        remainingAttempts: dto.data.attributes.remainingAttempts,
      };

      return Result.ok(generateOTPModel);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.GENERATE_OTP))
      );
    }
  }

  mapToDTO(domain: GenerateOTPModel): Result<GenerateOTPRequestDto> {
    try {
      const generateOTPRequest = new GenerateOTPRequestDto(
        domain.phoneNumber,
        domain.countryCode
      );

      return Result.ok(generateOTPRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.GENERATE_OTP))
      );
    }
  }
}
