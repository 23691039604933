import { BaseDTO } from "@/core/base/BaseDTO";
import { BaseDomain } from "@/core/base/BaseDomain";
import { IMapper } from "@/core/base/IMapper";
import { UnknownCause } from "@/core/cause/UnknownCause";
import { MapperException } from "@/core/exception/base/MapperException";
import { Result } from "@/core/utility/ResultType";
import { MAPPER } from "@/application/constants/Mapper";
import { PaginationModel } from "@/domain/base/pagination/model/PaginationModel";
import { ProductListRequestDto } from "@/domain/product/product-list/dto/ProductListRequestDto";
import { ProductListResponseDto } from "@/domain/product/product-list/dto/ProductListResponseDto";
import { ProductListModel } from "@/domain/product/product-list/model/ProductListModel";
import { ProductModelSettingModel } from "@/domain/product/product-list/model/ProductModelSettingModel";
import { ProductPackageModel } from "@/domain/product/product-list/model/ProductPackageModel";
import { ProductFAQModel } from "@/domain/product/product-list/model/ProductFAQModel";
import { useI18n } from "vue-i18n";

export class ProductListMapper implements IMapper<BaseDomain, BaseDTO> {
  mapToDomain(dto: ProductListResponseDto): Result<ProductListModel[]> {
    try {
      const productList: ProductListModel[] = [];

      if (dto.data.length > 0) {
        for (const item of dto.data) {
          const productPackages: ProductPackageModel[] =
            item.attributes.productPackages?.data.map((pkg) => {
              const productModelSettings: ProductModelSettingModel[] =
                pkg.attributes.productModelSettings?.data.map((setting) => {
                  return new ProductModelSettingModel(
                    Number(setting.id),
                    setting.attributes.name,
                    setting.attributes.description,
                    setting.attributes.selected
                  );
                });

              return new ProductPackageModel(
                productModelSettings,
                Number(pkg.id),
                pkg.attributes.name,
                Number(pkg.attributes.price),
                Number(pkg.attributes.currency),
                pkg.attributes.description,
                Number(pkg.attributes.period),
                Number(pkg.attributes.quota),
                pkg.attributes.purchased
              );
            });

          const productFAQs: ProductFAQModel[] =
            item.attributes.productFAQs?.data.map((pfaq) => {
              return new ProductFAQModel(
                Number(pfaq.id),
                pfaq.attributes.question,
                pfaq.attributes.answer
              );
            });

          const productListModel: ProductListModel = {
            product: {
              id: Number(item.id),
              shortCode: item.attributes.shortCode,
              name: item.attributes.name,
              description: item.attributes.description,
              productPackages: productPackages,
              productFAQs: productFAQs
            },
          };

          productList.push(productListModel);
        }
        return Result.ok(productList);
      }
      return Result.ok(productList);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.PRODUCT.PRODUCT_LIST))
      );
    }
  }

  mapToDTO(domain: ProductListModel): Result<ProductListRequestDto> {
    try {
      const i18n = useI18n();
      /*
      const pagiModel: PaginationModel = new PaginationModel(
        domain.page,
        domain.pageSize
      );

      const pagiRequest: PaginationRequestDto = paginationMapper
        .mapToDTO(pagiModel)
        .getValue();
*/
      const productListRequest = new ProductListRequestDto(i18n.locale.value);

      return Result.ok(productListRequest);
    } catch {
      return Result.fail(
        new MapperException(new UnknownCause(MAPPER.PRODUCT.PRODUCT_LIST))
      );
    }
  }
}
