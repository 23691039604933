import { RepositoryException } from "@/core/exception/base/RepositoryException";
import { Result } from "@/core/utility/ResultType";
import { REPOSITORY } from "@/application/constants/Repository";
import { BadRequestException } from "@/core/exception/BadRequestException";
import { UnknownCause } from "@/core/cause/UnknownCause";
import AcceptInviteService from "../service/AcceptInviteService";
import { IAcceptInviteRepository } from "@/domain/staff/invite-staff/accept/repository/IAcceptInviteRepository";
import { AcceptInviteRequestDto } from "@/domain/staff/invite-staff/accept/dto/AcceptInviteRequestDto";

const acceptInviteService = new AcceptInviteService();

export class AcceptInviteRepository implements IAcceptInviteRepository {
  async acceptInvite(request: AcceptInviteRequestDto): Promise<Result<any>> {
    try {
      return await acceptInviteService
        .invite(request)
        .then((response) => {
          if (response.isSuccess) {
            return Result.ok<any>(response.getValue());
          } else {
            return Result.fail<any>(response.getError());
          }
        })
        .catch((error) => {
          return Result.fail<string>(new BadRequestException(error));
        });
    } catch (error) {
      return Result.fail<string>(new RepositoryException(new UnknownCause(REPOSITORY.STAFF.ACCEPT_INVITE)));
    }
  }
}
