import { BaseDTO } from "@/core/base/BaseDTO";
import { SurveyQuestionsModel } from "../model/SurveyQuestionsModel";

export class CreatePollRequestDto extends BaseDTO {
  data: {
    title?: string;
    description?: string;
    startDate?: Date;
    endDate?: Date;
    surveyQuestions?: SurveyQuestionsModel[];
    isPrivate?: boolean;
  }
  constructor(
    title?: string,
    description?: string,
    startDate?: Date,
    endDate?: Date,
    surveyQuestions?: SurveyQuestionsModel[],
    isPrivate?: boolean
  ) {
    super();
    this.data = {
      title,
      description,
      startDate,
      endDate,
      surveyQuestions,
      isPrivate
    }
  }
}
