export const REPOSITORY = {
  LOGIN: "LoginRepository",
  RESET_PASSWORD: "ResetPasswordRepository",
  FORGOT_PASSWORD: "ForgotPasswordRepository",
  GENERATE_OTP: "GenerateOTPRepository",
  VERIFY_OTP: "VerifyOTPRepository",
  COUNTRY_LIST: "CountryListRepository",
  UPDATE_USER: "UpdateUserRepository",
  ABOUT_USER: "AboutUserRepository",
  CHANGE_TENANT_ESTATE: "ChangeTenantEstateRepository",
  SEND_MESSAGE: "SendMessageRepository",
  RETRY_ANSWER: "RetryAnswerRepository",
  SESSION_LIST: "SessionListRepository",
  TENANT_USERS_SESSION_LIST: "TenantUsersSessionListRepository",
  FILE_UPLOAD: "FileUploadRepository",

  STAFF: {
    STAFF_LIST: "StaffListRepository",
    ADD_STAFF: "AddStaffRepository",
    DELETE_STAFF: "DeleteStaffRepository",
    INVITE_STAFF: "InviteStaffRepository",
    ACCEPT_INVITE: "AcceptInviteRepository",
  },
  PRODUCT: {
    PRODUCT_LIST: "ProductListRepository",
    BUY_PRODUCT: "BuyProductRepository",
    UPDATE_PRODUCT_MODEL_SETTING: "UpdateProductModelSettingRepository",
    GET_PRODUCT: "GetProductRepository",
    GET_PURCHASED_PRODUCT: "GetPurchasedProductRepository",
  },
  STATISTIC: "StatisticRepository",
  POLLSTER: {
    CREATE_POLL: "CreatePollRepository",
    POLL_LIST: "PollListRepository",
    STATISTIC: {
      POLL_LIST: "StatisticPollListRepository",
      POLL_MESSAGE_LIST: "StatisticPollMessageListRepository",
      POLL_RESPONSE_LIST: "StatisticPollResponseListRepository",
      POLL_SESSION_DETAIL: "StatisticPollSessionDetailRepository",
    },
    POLL_DETAIL: "PollDetailRepository",
    DELETE_POLL: "DeletePollRepository",
    UPDATE_POLL: "UpdatePollRepository",
    INVITE_USER: "InviteUserRepository",
  },
  INTERVIEW: {
    CREATE_INTERVIEW: "CreateJobPostingRepository",
    INTERVIEW_LIST: "JobPostingListRepository",
    INTERVIEW_DETAIL: "JobPostingDetailRepository",
    DELETE_INTERVIEW: "DeleteJobPostingRepository",
    UPDATE_INTERVIEW: "UpdateJobPostingRepository",
    ADD_CANDIDATE: "AddCandidateRepository",
  }
};
